import { combineReducers } from "redux";
import LayoutReducer from "./Layout/LayoutReducer";
import LanguageReducer from "./Layout/LanguageReducer";
import HeaderReducer from "./Header/HeaderReducer";
import HomeReducer from "./Home/HomeReducer";
import AllItemsReducer from "./AllItems/AllItemsReducer";
import CategoriesReducer from "./AllCategories/CategoriesReducer";
import ItemReducer from "./SingleItem/ItemReducer";
import AddressReducer from "./CustomerAddress/AdressReducer";
import VendorReducer from "./VendorShop/VendorReducer";
import PaymentReducer from "./Payments/PaymentReducer";
import UserReducer from "./auth/UserReducer";
import OrderReducer from "./Orders/OrderReducer";
import ReturnReducer from "./Return/ReturnReducer";
import CartReducer from "./Cart/CartReducer";
import SelectReducer from "./SelectAddress/SelectAddressReducer";
import WalletReducer from "./Wallet/WalletReducer";
import NotFound from "./NotFound/NotFoundReducer.js";
import NotificationsReducer from "./Notifications/NotificationsReducer";

export default combineReducers({
  layout: LayoutReducer,
  language: LanguageReducer,
  header: HeaderReducer,
  home: HomeReducer,
  allItems: AllItemsReducer,
  item: ItemReducer,
  customerAddress: AddressReducer,
  vendor: VendorReducer,
  auth: UserReducer,
  payment: PaymentReducer,
  order: OrderReducer,
  returns: ReturnReducer,
  cart: CartReducer,
  select: SelectReducer,
  wallet: WalletReducer,
  categories: CategoriesReducer,
  notifications: NotificationsReducer,
  notFound: NotFound,
});
