import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import EmptyCartState from "../../assets/images/Cart/Cart-Empty/empty_cart_art.svg";
import { ReactComponent as NoonWBMasterCard } from "../../assets/icons/NoonWBMasterCard.svg";
import { ReactComponent as VisaCardWB } from "../../assets/icons/VisaCardWB.svg";
import { ReactComponent as CashBW } from "../../assets/icons/CashBW.svg";
import { ReactComponent as AmericanExpBW } from "../../assets/icons/AmericanExpBW.svg";
import { BsCheck } from "react-icons/bs";
import { set_loading } from "../../redux/actions/LayoutAction";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import Divider from "@material-ui/core/Divider";
import "../../assets/styles/component/mobile/cartmobile.scss";
import WishlistCardMob from "../../components/mobile/WishlistCardMob";
import { set_cart_items, set_wish_list } from "../../redux/actions/CartAction";
import { apiJson } from "../../Api";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { set_user } from "../../redux/actions/AuthAction";
import { getMobileImage } from "../../utils/utils";
import HeaderMobile from "../../components/mobile/HeaderMobile";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import discount from "../../assets/images/discount.png";
import ViewCoupons from "./ViewCoupons";
import { useOutsideAlerter } from "../../utils/CustomHooks";

const CartMobile = (props) => {
  const { t } = useTranslation();
  const [flag, setFlag] = useState(false);
  const [dataItem, setDataItem] = useState(false);
  const [coupon, setCoupon] = useState(
    props.cart_items?.coupon_code ? props.cart_items.coupon_code : ""
  );
  const [redirectPage, setRedirectPage] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [couponExpired, setCoupopnExpired] = useState(false);
  const [couponDropdownOpen, setCouponDropdownOpen] = useState(false);
  const [showCoupons, setShowCoupons] = useState(false);
  const sideCouponsRef = useRef(null);
  useOutsideAlerter(sideCouponsRef, setShowCoupons);

  let message = "";
  const suggestme = 100 - props.cart_items?.sub_total;
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    document.title = "Cart";
    props.set_user({
      guestSignin: false,
    });
    props.set_loading(true);
    apiJson.cart.getCartItems(props.countryId).then((response) => {
      props.set_loading(false);
      if (response.success) {
        props.set_cart_items({ cart_items: response.data });
        setDataItem(true);
      } else if (!response.status) {
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  }, []);

  const addCoupon = (code = "") => {
    setFlag(true);
    apiJson.cart.addCoupon(code || coupon, props.countryId).then((response) => {
      setFlag(false);
      setShowCoupons(false)
      if (response.success) {
        props.set_cart_items({ cart_items: response.data });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : t("cart-page.wrong-coupon");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const removeCoupon = (coupon) => {
    setFlag(true);
    apiJson.cart.removeCoupon(props.countryId).then((response) => {
      setFlag(false);
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  useEffect(() => {
    props.cart_items.cart_data_changed &&
      setDataChanged(props.cart_items?.cart_data_changed);

    props.cart_items.cart_data_changed &&
      apiJson.cart.updateCartReset(props.countryId);
    props.cart_items.cart_data_changed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          cart_data_changed: false,
        },
      });
    // eslint-disable-next-line
  }, [props.cart_items.cart_data_changed]);

  useEffect(
    () =>
      setCoupon(
        props.cart_items?.coupon_code ? props.cart_items.coupon_code : ""
      ),
    [props.cart_items?.coupon_code]
  );

  useEffect(() => {
    props.cart_items.expired_coupon_removed &&
      setCoupopnExpired(props.cart_items?.expired_coupon_removed);

    props.cart_items.expired_coupon_removed &&
      apiJson.cart.discountReset(props.countryId);
    props.cart_items.expired_coupon_removed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          expired_coupon_removed: false,
        },
      });
    // eslint-disable-next-line
  }, [props.cart_items.expired_coupon_removed]);

  const checkoutBtnClicked = (e) => {
    e.preventDefault();

    if (props.auth) {
      apiJson
        .select.getDefault()
        .then(resp => {
          if (resp.success) {
            if (resp.data.length > 0) {
              const request = {
                order: {
                  address_id: resp.data[0].id,
                  is_delivery: 1
                },
              };

              apiJson.select
                .varifyAdress(request, props.countryId)
                .then((response) => {
                  if (response.success) {
                    props.set_cart_items({
                      order: {
                        address: resp.data[0],
                        id: response.data.id,
                        createdAddress: response.data,
                      },
                    });
                    setRedirect({
                      value: true,
                      path:
                        "/" + props.countryUrl + "-" + props.language + "/checkout",
                    });
                  } else if (!response.status) {
                    message = t("errors.wrong");
                    showError();
                  } else {
                    if (response.status >= 400 && response.status < 500) {
                      message = response.message
                        ? response.message
                        : response.errors
                          ? response.errors
                          : t("errors.wrong");
                      showError();
                    } else {
                      message = response.message
                        ? response.message !== ""
                          ? response.message
                          : t("errors.wrong")
                        : t("errors.wrong");
                      showError();
                    }
                  }
                });
            }
            else {
              props.set_cart_items({
                order: {
                  address: null,
                  id: null,
                  createdAddress: null,
                },
              });

              setRedirect({
                value: true,
                path:
                  "/" + props.countryUrl + "-" + props.language + "/checkout",
              });
            }
          }
        });
    }
    else {
      props.set_cart_items({
        order: {
          address: null,
          id: null,
          createdAddress: null,
        },
      });

      setRedirect({
        value: true,
        path:
          "/" + props.countryUrl + "-" + props.language + "/checkout",
      });
    }
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <>
        <Fragment>
          <div className="home-mob">
            <HeaderMobile />
          </div>
          <div className="cart-page-mobile-main">
            {dataItem ? (
              props.cart_items?.line_items?.length > 0 ? (
                <div className="wrapper">
                  <div className="cart-page-card-list pb-5">

                    {/* {

                      props.cart_items?.sub_total < 100 &&
                      <div className="suggestfree">
                        <span className="suggesttext">
                          You're Only AED&nbsp;
                          <span className="suggestvalue">
                            {
                              Math.round((suggestme + Number.EPSILON) * 100) / 100
                            }
                          </span>
                          &nbsp;Away From Free Shipping!
                        </span>
                      </div>
                    } */}

                    <div className="order-summary">
                      <p className="m-0 title">{t("cart-page.cart")} ({props.cart_items.line_items?.length})</p>
                      {dataChanged && (
                        <>
                          <hr className="m-0" />
                          <div className="data-change">
                            <div className="w-80 ">
                              <div>{t("data-changed.quantity-changed")}</div>
                              <div className="mt-1">
                                {t("data-changed.prices-changed")}
                              </div>
                              <div className="mt-1">
                                {t("data-changed.review-cart")}
                              </div>
                            </div>
                            <div className="w-8  dismiss-border dismiss m-auto">
                              <span
                                className="pointer"
                                onClick={() => setDataChanged(false)}
                              >
                                {" "}
                                {t("data-changed.dismiss")}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="summary">
                        <p className="m-0 title">{t("cart-page.order-summary")}</p>
                        <hr className="m-0" />
                        <div className="d-flex align-items-center justify-content-between sub-total-row">
                          <p className="m-0">{t("cart-page.sub")}</p>
                          <p className="m-0">
                            {props.country.toLowerCase() === "saudi arabia"
                              ? "SAR "
                              : props.country.toLowerCase() ===
                                "united arab emirates"
                                ? "AED "
                                : "EGP "}
                            {props.cart_items.sub_total}</p>
                        </div>
                        {parseFloat(props.cart_items?.discount) !== 0 && (
                          <>
                            <hr className="m-0" />
                            <div className="d-flex align-items-center justify-content-between sub-total-row">
                              <p className="m-0">{t("cart-mobile.dicount")}</p>
                              <p className="m-0">
                                {props.country.toLowerCase() === "saudi arabia"
                                  ? "SAR "
                                  : props.country.toLowerCase() ===
                                    "united arab emirates"
                                    ? "AED "
                                    : "EGP "}
                                {props.cart_items.discount}</p>
                            </div>
                          </>
                        )}
                        <hr className="m-0" />
                        <div>
                          <div
                            onClick={() => setCouponDropdownOpen(!couponDropdownOpen)}
                            className="d-flex align-items-center justify-content-between sub-total-row">
                            <p className="m-0">{t("cart-page.add-a-coupon")}</p>
                            <AiOutlineDown
                            />
                          </div>
                          {
                            couponDropdownOpen ?
                              <div className="coupon-section">
                                <div className="coupon-input-and-label">
                                  <input
                                    type="text"
                                    className="coupon-input"
                                    placeholder={t("cart-mobile.enter-coupon-ph")}
                                    value={coupon}
                                    disabled={props.cart_items?.coupon_code}
                                    onChange={(e) => setCoupon(e.target.value)}
                                  />
                                  {props.cart_items?.coupon_code && (
                                    <div className="d-flex pr-2 align-items-center bg-white">
                                      <BsCheck size={20} color="green" />
                                    </div>
                                  )}

                                  <Divider orientation="vertical" flexItem />
                                  {props.cart_items?.coupon_code ? (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (localStorage.getItem("access_token")) {
                                          removeCoupon();
                                        } else {
                                          message = "Please login to remove coupon";
                                          showError();
                                        }
                                      }}
                                      className="coupon-apply-buton"
                                    >
                                      {flag ? (
                                        <div className="d-flex justify-content-center align-items-center w-100">
                                          <ClipLoader color="#000" loading size={20} />
                                        </div>
                                      ) : (
                                        t("cart-page.remove")
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (localStorage.getItem("access_token")) {
                                          if (coupon.length > 0) {
                                            addCoupon();
                                          }
                                        } else {
                                          message = "Please login to apply coupon";
                                          showError();
                                        }
                                      }}
                                      className="coupon-apply-buton"
                                    >
                                      {flag ? (
                                        <div className="d-flex justify-content-center align-items-center w-100">
                                          <ClipLoader color="#000" loading size={20} />
                                        </div>
                                      ) : (
                                        t("cart-mobile.apply-btn")
                                      )}
                                    </button>
                                  )}
                                </div>
                              </div>
                              :
                              <></>
                          }
                        </div>
                        {couponExpired && (
                          <>
                            <hr className="m-0" />
                            <div className="data-change">
                              <div className="w-90 ">
                                <div>{t("coupon-expire.coupon-expired")}</div>

                                <div className="mt-1">
                                  {t("coupon-expire.review-cart")}
                                </div>
                              </div>
                              <div className="w-8  dismiss-border dismiss m-auto">
                                <span
                                  className="pointer"
                                  onClick={() => setCoupopnExpired(false)}
                                >
                                  {" "}
                                  {t("coupon-expire.dismiss")}
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        <hr className="m-0" />
                        <div
                          className="sub-total-row d-flex justify-content-between align-items-center"
                          onClick={() => setShowCoupons(!showCoupons)}
                        >
                          <span>
                            <img src={discount} />
                            <span className="ml-2">
                              {t("cart-page.view-offers")}
                            </span>
                          </span>
                          <AiOutlineRight />
                        </div>
                      </div>
                    </div>

                    {props.cart_items.line_items.map((item) => (
                      <WishlistCardMob
                        key={item.id}
                        item={item}
                        service_type={item.orderable.service_type}
                        cart_items={props.cart_items}
                        type={item.orderable_type}
                        wishlisted_item={item.wishlisted_item}
                        id={item.id}
                        orderable_id={item.orderable.id}
                        sku={item.orderable.sku}
                        user_id={props.cart_items.user_id}
                        sub_total={props.cart_items.sub_total}
                        name={item.orderable[`product_name_${props.language}`]}
                        brand={item.orderable[`brand_name_${props.language}`]}
                        price={item.price}
                        discountPrice={item.sale_price}
                        start_at={item.start_at}
                        end_at={item.end_at}
                        image_path={getMobileImage(
                          item.orderable.featured_image_resized,
                          item.orderable.featured_image
                        )}
                        sold_by={item.orderable[`store_name_${props.language}`]}
                        p_quantity={item.quantity ? item.quantity : false}
                        cartOrWishlist="cart"
                      />
                    ))}
                  </div>

                  {/* <div className="enter-coupon-container">
                    {dataChanged && (
                      <div className="data-change">
                        <div className="w-80 ">
                          <div>{t("data-changed.quantity-changed")}</div>
                          <div className="mt-1">
                            {t("data-changed.prices-changed")}
                          </div>
                          <div className="mt-1">
                            {t("data-changed.review-cart")}
                          </div>
                        </div>
                        <div className="w-8  dismiss-border dismiss m-auto">
                          <span
                            className="pointer"
                            onClick={() => setDataChanged(false)}
                          >
                            {" "}
                            {t("data-changed.dismiss")}
                          </span>
                        </div>
                      </div>
                    )}
                    {couponExpired && (
                      <div className="data-change">
                        <div className="w-90 ">
                          <div>{t("coupon-expire.coupon-expired")}</div>

                          <div className="mt-1">
                            {t("coupon-expire.review-cart")}
                          </div>
                        </div>
                        <div className="w-8  dismiss-border dismiss m-auto">
                          <span
                            className="pointer"
                            onClick={() => setCoupopnExpired(false)}
                          >
                            {" "}
                            {t("coupon-expire.dismiss")}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="coupon-input-and-label">
                      <input
                        type="text"
                        className="coupon-input"
                        placeholder={t("cart-mobile.enter-coupon-ph")}
                        value={coupon}
                        disabled={props.cart_items?.coupon_code}
                        onChange={(e) => setCoupon(e.target.value)}
                      />
                      {props.cart_items?.coupon_code && (
                        <div className="d-flex pr-2 align-items-center bg-white">
                          <BsCheck size={20} color="green" />
                        </div>
                      )}

                      <Divider orientation="vertical" flexItem />
                      {props.cart_items?.coupon_code ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            if (localStorage.getItem("access_token")) {
                              removeCoupon();
                            } else {
                              message = "Please login to remove coupon";
                              showError();
                            }
                          }}
                          className="coupon-apply-buton"
                        >
                          {flag ? (
                            <div className="d-flex justify-content-center align-items-center w-100">
                              <ClipLoader color="#000" loading size={20} />
                            </div>
                          ) : (
                            t("cart-page.remove")
                          )}
                        </button>
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            if (localStorage.getItem("access_token")) {
                              if (coupon.length > 0) {
                                addCoupon();
                              }
                            } else {
                              message = "Please login to apply coupon";
                              showError();
                            }
                          }}
                          className="coupon-apply-buton"
                        >
                          {flag ? (
                            <div className="d-flex justify-content-center align-items-center w-100">
                              <ClipLoader color="#000" loading size={20} />
                            </div>
                          ) : (
                            t("cart-mobile.apply-btn")
                          )}
                        </button>
                      )}
                    </div>
                    <div className="enter-coupon-container-row-1 mt-4 px-3">
                      <span className="subtotal-items label-for">
                        {t("cart-mobile.subtotal")}{" "}
                        <span className="totalItems">
                          ({props.cart_items.line_items.length}{" "}
                          {t("items.items")})
                        </span>
                      </span>
                      <span className="totalprice label-for">
                        {props.country.toLowerCase() === "saudi arabia"
                          ? "SAR "
                          : props.country.toLowerCase() ===
                            "united arab emirates"
                            ? "AED "
                            : "EGP "}
                        {props.cart_items?.sub_total}
                      </span>
                    </div>

                    {parseFloat(props.cart_items?.discount) !== 0 && (
                      <div className="enter-coupon-container-row-1 mt-4 px-3">
                        <span className="subtotal-items label-for">
                          {t("cart-mobile.discounts")}
                        </span>
                        <span className="totalprice label-for">
                          {props.country.toLowerCase() === "saudi arabia"
                            ? "SAR "
                            : props.country.toLowerCase() ===
                              "united arab emirates"
                              ? "AED "
                              : "EGP "}
                          {props.cart_items?.discount}
                        </span>
                      </div>
                    )}

                    {props.cart_items?.coupon_code && (
                      <div className="enter-coupon-container-row-1 mt-4 px-3">
                        <span className="subtotal-items label-for">
                          {t("checkout-mobile.coupon")}
                        </span>
                        <span className="totalprice label-for">
                          {props.cart_items?.coupon_code}
                        </span>
                      </div>
                    )}

                    <div className="enter-coupon-container-row-3 mt-4 px-3">
                      <span className="Total-price-label">
                        {t("cart-mobile.total")}{" "}
                        <span className="totalItems">
                          {t("cart-mobile.inclusive-of-vat")}
                        </span>
                      </span>
                      <span className="totalprice-ti">
                        {props.country.toLowerCase() === "saudi arabia"
                          ? "SAR "
                          : props.country.toLowerCase() ===
                            "united arab emirates"
                            ? "AED "
                            : "EGP "}
                        {(
                          parseFloat(props.cart_items.sub_total) -
                          (props.cart_items.discount
                            ? parseFloat(props.cart_items.discount)
                            : 0)
                        ).toFixed(2)}
                      </span>
                    </div>

                    <div className="bottom-svgs">
                      <NoonWBMasterCard />
                      <VisaCardWB />
                      <CashBW />
                      <AmericanExpBW />
                    </div>
                  </div> */}
                </div>
              ) : (
                <div className="cartempty-card-area-mobile">
                  <div className="image-container">
                    <img
                      src={EmptyCartState}
                      className="img-fluid"
                      alt="emptycart"
                      height="100%"
                      width="100%"
                    />
                  </div>
                  <div className="text-container">
                    <div className="cart-text cart-head-text">
                      {t("cart-mobile.empty.is-empty")}
                    </div>
                    <div className="cart-text cart-sub-text">
                      {t("cart-mobile.empty.add-items-to-wishlist")}
                    </div>
                  </div>
                  <Link
                    to={"/" + props.countryUrl + "-" + props.language + "/"}
                  >
                    <button className="cart-continueshopping-button ">
                      {t("cart-page.empty.continue-shop-btn")}
                    </button>
                  </Link>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
          {dataItem && props.cart_items.line_items.length !== 0 && (
            <div className="button-main">
              <div className="bottom-button">
                <button
                  onClick={checkoutBtnClicked}
                  className="buy-button"
                >
                  {t("cart-mobile.buy")} {props.cart_items.line_items.length}{" "}
                  {t("cart-mobile.items-for")}{" "}
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR "
                    : props.country.toLowerCase() === "united arab emirates"
                      ? "AED "
                      : "EGP "}
                  {(
                    parseFloat(props.cart_items.sub_total) -
                    (props.cart_items.discount
                      ? parseFloat(props.cart_items.discount)
                      : 0)
                  ).toFixed(2)}
                </button>
              </div>
            </div>
          )}
        </Fragment>
      </>
      {showCoupons && (
        <ViewCoupons
          showCoupons={showCoupons}
          refs={sideCouponsRef}
          coupons={props.cart_items.coupons}
          onApply={(code) => {
            if (props.cart_items.coupon_code) {
              apiJson.cart.removeCoupon(props.countryId).then((response) => {
                if (response.success) {
                  props.set_cart_items({ cart_items: response.data });
                }

                addCoupon(code);
              });
            } else {
              addCoupon(code);
            }
          }}
          onRemove={removeCoupon}
          appliedCode={props.cart_items.coupon_code}
        />
      )}

      {redirectPage && (
        <Redirect
          push
          to={{
            pathname:
              "/" + props.countryUrl + "-" + props.language + "/select-address",
            state: { coupon_id: 1 },
          }}
        />
      )}
      {redirect.value && (
        <Redirect
          push
          to={{
            pathname: redirect.path,
            state: redirect.state,
          }}
        />
      )}
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    cart_items: state.cart.cart_items,
    wish_list: state.cart.wish_list,
    country: state.language.country,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    load: state.layout.loading,
    auth: state.auth.isSignedIn,
  };
};

const mapDispatchToProps = {
  set_wish_list,
  set_cart_items,
  set_user,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartMobile);
