import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import Logo from "../../assets/images/logo.png";
import "../../assets/styles/user-Auth-Option-1/loginmoboption1.scss";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useState } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_user } from "../../redux/actions/AuthAction";
import { Link, Redirect, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { apiJson } from "../../Api";
import { useFcmPermission } from "../../utils/useFcmPermission";

function LoginMobOption1(props) {
  const { t } = useTranslation();
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState(false);
  const [redirectToSignup, setRedirectToSignup] = useState(false);
  const { requestPermission } = useFcmPermission()

  let message = "";
  const history = useHistory();

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(true);
    let params = localStorage.getItem("user_key")
      ? {
        email,
        password,
        user_key: localStorage.getItem("user_key"),
      }
      : {
        email,
        password,
      };
    $.auth
      .emailSignIn(params)
      .then((response) => {
        localStorage.removeItem("user_key");
        localStorage.removeItem("guest_user_address");
        localStorage.setItem(
          "access_token",
          $.auth.retrieveData("authHeaders")["access-token"]
        );
        localStorage.setItem(
          "client",
          $.auth.retrieveData("authHeaders").client
        );
        localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);

        props.set_user({ isSignedIn: true, user: $.auth.user });
        message = "Logged in successfully";
        showSuccess();
        setData(false);
        setRedirect(true);
      })
      .fail((error) => {
        setData(false);
        if (error.data?.errors?.base) {
          message = error.data.errors.full_messages[0];
          showError();
        } else if (error.data?.errors) {
          message = error.data.errors[0];
          showError();
        } else if (error?.data?.status === "password_reset_required") {
          message = t("forget-pw-mob.success-message");
          showSuccess();
        } else {
          message = t("errors.network");
          showError();
        }
      });
  };

  var getCookies = function () {
    var pairs = document.cookie?.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
    }
    return cookies;
  };

  useEffect(() => {
    if (
      getCookies()?.authHeaders &&
      $?.auth?.retrieveData("authHeaders")?.client
    ) {
      apiJson.auth
        .validateLoginToken({
          headers: {
            client: $?.auth?.retrieveData("authHeaders")?.client,
            "access-token":
              $?.auth?.retrieveData("authHeaders")["access-token"],
            uid: $?.auth?.retrieveData("authHeaders")?.uid,
          },
        })
        .then((response) => {
          const role = response?.data?.data?.roles?.filter(
            (role) => role === "customer"
          )?.length;
          if (response.success && role > 0) {
            localStorage.removeItem("user_key");
            localStorage.removeItem("guest_user_address");
            localStorage.setItem(
              "access_token",
              $.auth.retrieveData("authHeaders")["access-token"]
            );
            localStorage.setItem(
              "client",
              $.auth.retrieveData("authHeaders").client
            );
            localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);

            props.set_user({
              isSignedIn: true,
              user: response.data.data,
            });
            setRedirect(true);
          } else {
            props.set_user({ isSignedIn: false, user: {} });
            localStorage.removeItem("access_token");
            localStorage.removeItem("client");
            localStorage.removeItem("uid");
            document.cookie =
              "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.title = "Login";
  }, []);

  return (
    <div className="login-mob-option-1 ml-auto mr-auto">
      <div className="wrapper">
        <div className="logo-and-icon">
          <div className="logo-cont">
            <div onClick={() => setRedirect(true)} className="logo-size">
              <Link to={"/" + props.countryUrl + "-" + props.language}>
                <img src={Logo} width="100%" height="100%" alt="" />
              </Link>
            </div>
          </div>
          <div
            onClick={() =>
              props.address ? history.goBack() : setRedirect(true)
            }
            className="cross-icon"
          >
            <IoMdClose size="40" />
          </div>
        </div>
        <div className="create-account-title">
          <span>{t("login.mobile.title")}</span>
        </div>

        <label className="e-label">{t("login.mobile.e-label")}</label>

        <div className="d-flex email">
          <input
            className="e-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("login.mobile.e-placeholder")}
          />
        </div>
        <label className="e-label">{t("login.mobile.pw-label")}</label>

        <div className="d-flex pass-cont justify-content-center align-items-center">
          <input
            className="password"
            type={`${showPass ? "text" : "password"}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("login.mobile.pw-placeholder")}
          />
          {showPass ? (
            <BsFillEyeFill
              onClick={() => setShowPass(!showPass)}
              className="eye-icon"
              size="14px"
              color="rgb(126, 133, 155)"
            />
          ) : (
            <BsFillEyeSlashFill
              onClick={() => setShowPass(!showPass)}
              className="eye-icon"
              size="14px"
              color="rgb(126, 133, 155)"
            />
          )}
        </div>
        <div className="forget-password">
          <Link to="/reset" className="forget-password">
            <span>{t("login.mobile.forget-pw")}</span>
          </Link>
        </div>
        <div onClick={handleSubmit} className="sign-in">
          {data ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <ClipLoader color="#fff" loading size={20} />
            </div>
          ) : (
            t("login.mobile.signin-btn")
          )}
        </div>
        <div
          onClick={() =>
            props.address ? props.clickSignup(true) : setRedirectToSignup(true)
          }
          className="signup"
        >
          {t("login.mobile.no-account")}{" "}
          <span className="signup-text">{t("login.mobile.sign-up")}</span>
        </div>

        {redirect && !props.address && (
          <Redirect
            push
            to={"/" + props.countryUrl + "-" + props.language + "/"}
          />
        )}
        {redirectToSignup && <Redirect push to="/signup" />}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_user,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginMobOption1);
