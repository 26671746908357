import { apiJson } from "../Api";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase/firebaseConfig";

export const useFcmPermission = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => registration)
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }

  const requestPermission = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
            serviceWorkerRegistration: registration,
          })
            .then((token) => {
              saveTokenToBackend(token);
            })
            .catch((error) => {
              console.error("Error getting token:", error);
            });
        })
        .catch((error) => {
          console.error("Service Worker not ready:", error);
        })
        .catch((error) => console.error(error));
    }
  };

  const saveTokenToBackend = (token) => {
    apiJson.pushnotificationmessages
      .saveToken({
        token: token,
      })
      .then((response) => {
        if (response.success) {
          localStorage.setItem("device_token", token);
        }
      });
  };

  return { requestPermission };
};
