export const type = {
  SET_DIRECTION: "SET_DIRECTION",
  SET_WIDTH: "SET_WIDTH",
  SET_CATEGORIES: "SET_CATEGORIES",
  SET_HOME_CATEGORIES: "SET_HOME_CATEGORIES",
  SET_ALL_ITEMS: "SET_ALL_ITEMS",
  SET_SINGLE_ITEM: "SET_SINGLE_ITEM",
  SET_CUSTOMER_ADDRESS: "SET_CUSTOMER_ADDRESS",
  SET_VENDOR_SHOP: "SET_VENDOR_SHOP",
  SET_PAYMENTS: "SET_PAYMENTS",
  SET_USER: "SET_USER",
  SET_ORDERS: "SET_ORDERS",
  SET_ORDER_DETAIL: "SET_ORDER_DETAIL",
  SET_RETURN_ITEMS: "SET_RETURN_ITEMS",
  SET_CART_ITEMS: "SET_CART_ITEMS",
  SET_WISH_LIST: "SET_WISH_LIST",
  SET_SELECT_ADDRESS: "SET_SELECT_ADDRESS",
  SET_WALLET_CREDIT: "SET_WALLET_CREDIT",
  SET_WALLET_TRANSFERS: "SET_WALLET_TRANSFERS",
  SET_ALL_CATEGORIES: "SET_ALL_CATEGORIES",
  SET_COUNTRY: "SET_COUNTRY",
  SET_SEARCH: "SET_SEARCH",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  CHANGE_REDIRECT: "CHANGE_REDIRECT",
  SET_LOADING: "SET_LOADING",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_NOTIFICATIONS_COUNT: "SET_NOTIFICATIONS_COUNT"
};
