import CouponCard from "../../components/customer/cart/CouponCard";
import "../../assets/styles/component/mobile/viewcoupons.scss";
import { useTranslation } from "react-i18next";

function ViewCoupons({
  showCoupons,
  coupons,
  onApply,
  onRemove,
  appliedCode,
  refs,
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="bottom-sheet-top"></div>
      <div className={`bottom-sheet ${showCoupons ? "open" : ""}`} ref={refs}>
        <div className="bottom-sheet-content">
          <h5
            style={{
              fontWeight: 600,
            }}
          >
            {t("cart-page.view-available-offers")}
          </h5>

          {coupons.map((coupon) => (
            <CouponCard
              coupon={coupon}
              onApply={onApply}
              appliedCode={appliedCode}
              onRemove={onRemove}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default ViewCoupons;
