import CouponCard from "./CouponCard";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

function CouponsModel({
  coupons,
  onApply,
  onRemove,
  showCoupons,
  appliedCode,
  setShowCoupons,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      show={showCoupons}
      onHide={() => setShowCoupons(false)}
      closeModal={() => setShowCoupons(false)}
      size="md"
      className="service-modal"
      centered
    >
      <Modal.Body>
        <div style={{ padding: "24px" }}>
          <h4
            style={{
              fontWeight: 600,
              position: "sticky",
              zIndex: 1,
              marginBottom: 0,
            }}
          >
            {t("cart-page.view-available-offers")}
          </h4>
        </div>
        <div
          style={{
            padding: "0px 24px 24px",
            display: "grid",
            gap: "10px",
            overflow: "auto",
            maxHeight: "calc(100vh - 250px)",
          }}
        >
          {coupons.map((coupon) => (
            <CouponCard
              coupon={coupon}
              onApply={onApply}
              appliedCode={appliedCode}
              onRemove={onRemove}
            />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CouponsModel;
