import { Fragment, useEffect, useState } from "react";
import "../../../assets/styles/customer/cart/cartsidebar.scss";
import "./notification.scss";
import { set_notifications } from "../../../redux/actions/NotificationsAction";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";

function Notifications(props) {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const getPushNotifications = async () => {
    apiJson.pushnotificationmessages.getPushNotifications().then((response) => {
      if (response.success) {
        props.set_notifications({
          notifications: response.data.push_notifications,
          count: response.data.count,
        });
        setDisabled(false);
      }
    });
  };

  const onMarkAsRead = (id) => {
    setDisabled(true);
    apiJson.pushnotificationmessages
      .pushNotificationMarkAsRead(id)
      .then((response) => {
        if (response.success) {
          getPushNotifications();
        }
      });
  };

  useEffect(() => {
    setChecked(props.bool);
  }, []);

  return (
    <Fragment>
      <div
        className={
          checked
            ? "cart-sidebar-with-translation-desktop"
            : "cart-sidebar-without-translation-desktop"
        }
        ref={props.refs}
      >
        <div className="panel" style={{ width: "100%" }}>
          <div className="sidebar">
            {props.notifications?.length ? (
              props.notifications.map((notification) => (
                <div
                  key={notification.id}
                  className="notification-card"
                  style={{
                    backgroundColor: notification.is_read
                      ? "#fff"
                      : "rgb(239, 242, 247)",
                    pointerEvents: notification.is_read ? "none" : "auto",
                  }}
                  onClick={() =>
                    !disabled &&
                    !notification.is_read &&
                    onMarkAsRead(notification.id)
                  }
                >
                  <h4>{notification.title}</h4>
                  <p>{notification.message}</p>
                </div>
              ))
            ) : (
              <h6 className="notification-empty">No notifications</h6>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
  };
};

const mapDispatchToProps = {
  set_notifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
