import React from "react";
import "../../../assets/styles/customer/cart/couponcard.scss";
import discount from "../../../assets/images/discount.png";
import { useTranslation } from "react-i18next";

const CouponCard = ({ coupon, onApply, appliedCode, onRemove }) => {
  const { t } = useTranslation();

  const {
    code,
    currency,
    max_discount,
    discount_type,
    flat_discount,
    percent_discount,
    min_order_amount,
    discount: discountAmount,
  } = coupon;

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <div className="coupon-card">
        <div
          style={{
            padding: "8px",
            borderBottom: "1px solid rgb(236, 235, 235)",
            color: "#742013",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            background: "#F7F9FE",
          }}
        >
          <img src={discount} />
          <span>
            {t("cart-page.save")}{" "}
            {discount_type === "flat_amount"
              ? parseFloat(flat_discount)
              : parseFloat(percent_discount)}{" "}
            {currency.toUpperCase()}
          </span>
        </div>

        <div style={{ color: "#000", padding: "10px 18px" }}>
          {discount_type === "flat_amount" ? (
            <>
              <p className="card-text" style={{ fontWeight: 700 }}>
                {t("cart-page.extra-flat-off", {
                  discount: `${parseFloat(
                    flat_discount
                  )} ${currency.toUpperCase()}`,
                })}
              </p>
              {min_order_amount && (
                <p className="card-text">
                  {t("cart-page.on-orders-over-off")}{" "}
                  {parseFloat(min_order_amount)} {currency.toUpperCase()}
                </p>
              )}
            </>
          ) : (
            <>
              <p className="card-text" style={{ fontWeight: 700 }}>
                {t("cart-page.extra-off", {
                  discount: parseFloat(discountAmount) * 100,
                })}
              </p>
              {max_discount && (
                <p className="card-text">
                  {t("cart-page.maximum-discount")} {parseFloat(max_discount)}{" "}
                  {currency.toUpperCase()}
                </p>
              )}
            </>
          )}

          <div
            className="mt-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative", overflow: "hidden" }}>
              <div
                className="coupon-code"
                onClick={() => navigator.clipboard.writeText(code)}
              >
                {code}
              </div>
            </div>

            <span
              className="apply"
              onClick={() =>
                appliedCode === code ? onRemove() : onApply(code)
              }
            >
              {appliedCode === code
                ? t("cart-page.coupon-remove")
                : t("cart-page.coupon-apply")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponCard;
