import { Fragment, useEffect, useState } from "react";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import HeaderMobile from "../HeaderMobile";
import { apiJson } from "../../../Api";
import { set_notifications } from "../../../redux/actions/NotificationsAction";
import { connect } from "react-redux";

function MobileNotifications(props) {
  const [disabled, setDisabled] = useState(false);
  const getPushNotifications = async () => {
    apiJson.pushnotificationmessages.getPushNotifications().then((response) => {
      if (response.success) {
        props.set_notifications({
          notifications: response.data.push_notifications,
          count: response.data.count,
        });
        setDisabled(false);
      }
    });
  };

  useEffect(() => {
    getPushNotifications();
  }, []);

  const onMarkAsRead = (id) => {
    setDisabled(true);
    apiJson.pushnotificationmessages
      .pushNotificationMarkAsRead(id)
      .then((response) => {
        if (response.success) {
          getPushNotifications();
        }
      });
  };

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={true}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <>
        <Fragment>
          <div className="home-mob">
            <HeaderMobile />
          </div>
          <div className="cart-page-mobile-main">
            {props.notifications?.length ? (
              props.notifications.map((notification) => (
                <div
                  key={notification.id}
                  className="notification-card"
                  style={{
                    backgroundColor: notification.is_read
                      ? "#fff"
                      : "rgb(239, 242, 247)",
                  }}
                  onClick={() =>
                    !disabled &&
                    !notification.is_read &&
                    onMarkAsRead(notification.id)
                  }
                >
                  <h4>{notification.title}</h4>
                  <p>{notification.message}</p>
                </div>
              ))
            ) : (
              <h6 className="notification-empty">No notifications</h6>
            )}
          </div>
        </Fragment>
      </>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
  };
};

const mapDispatchToProps = {
  set_notifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNotifications);
