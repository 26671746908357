import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/component/customer/itemDetail/ItemData.scss";
import { AiFillVideoCamera } from "react-icons/ai";
import ItemCmp from "./ItemCmpMobile";
import { connect } from "react-redux";

function ItemDataMobile(props) {
  const [img, setImg] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [video_clicked, setVideoClicked] = useState(false);
  const [activeImg, setActiveImg] = useState(undefined);

  useEffect(() => {
    setVideo(props.data.video);
    if (props.data) {
      setImg(
        props.data.img.map((item, index) => {
          return {
            id: item.id,
            src: item.img,
            active: index === 0 ? true : false,
          };
        })
      );
      setActiveImg(props.data.img[0].img);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getBreadcrumbList = (breadcrumb_category, list = []) => {
    if (breadcrumb_category) {
      list.push(breadcrumb_category);
      if (breadcrumb_category.parent_category) {
        getBreadcrumbList(breadcrumb_category.parent_category, list);
      }
    }
    return list;
  }

  const renderBreadcrumb = (breadcrumbList) => {
    return breadcrumbList.map((category, index) => (
      <React.Fragment key={category.id}>
        <Link to={{
          pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
          search: "?filter_by_category%5B%5D=" + category.id
        }}
        className="product-breadcrumb"
        >
          {category.title_en}
        </Link>
        {index < breadcrumbList.length - 1 && <span className="gt-sign">&gt;</span>}
      </React.Fragment>
    ));
  }

  const breadcrumbList = getBreadcrumbList(props.data.breadcrumb_category).reverse();

  return (
    <div className="item-data-desktop">
      <div>
        <div className="">
          {renderBreadcrumb(breadcrumbList)}
        </div>
        <div className="vendure-text123 pb-0">
          <Link to={{
            pathname: "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/items/products",
            search: "?filter_by_brand%5B%5D=" + props.data.brand_id
          }}
          ><span className="inf">Brand:</span> {props.data.brand_name}</Link>
        </div>
        <div className="vendure-text123 pt-0">
          <span className="inf">Country:&nbsp;{props.data?.country_of_origin ? props.data?.country_of_origin : ""}</span>
        </div>
        <h1 className="item-title">
          {props.data?.order_title + " "}
          {props.data
            ? props.data[`variantName_${props.language}`]
              ? props.data[`variantName_${props.language}`]
              : ""
            : ""}
        </h1>
      </div>

      <div className=" d-flex flex-direction-column">
        <div className="child-div-one">
          {img?.map((data, index) => (
            <div
              key={data.id}
              className={
                data.active
                  ? " d-flex justify-content-center align-items-center item-imgs  bg-white mt-1 active-item-border ml-auto mr-auto"
                  : "d-flex justify-content-center align-items-center item-imgs bg-white mt-1 inactive-item-border ml-auto mr-auto"
              }
              onClick={() => {
                setImg(
                  img.map((ele, i) => {
                    if (i === index)
                      return { id: ele.id, src: ele.src, active: true };
                    else return { id: ele.id, src: ele.src, active: false };
                  })
                );

                setActiveImg(data.src);
                setVideoClicked(false);
              }}
            >
              <img
                key={index}
                alt=""
                src={data.src}
                style={{ objectFit: "contain", maxWidth: "90%", height: "90%" }}
              />
            </div>
          ))}
          {
            video ?
              <div
                className={
                  video_clicked ?
                    "d-flex justify-content-center align-items-center item-imgs  bg-white mt-1 active-item-border ml-auto mr-auto video-thumbnail"
                    :
                    "d-flex justify-content-center align-items-center item-imgs  bg-white mt-1 inactive-item-border ml-auto mr-auto video-thumbnail"
                }
                onClick={() => {
                  setVideoClicked(!video_clicked);
                  setImg(
                    img.map((ele, i) => {
                      return { id: ele.id, src: ele.src, active: false };
                    })
                  );
                }
                }
              >
                <AiFillVideoCamera className="video-icon" />
              </div>
              :
              <></>
          }
        </div>

        <div className="child-div-two">
          <ItemCmp
            data={props.data}
            activeImg={activeImg}
            Img={img}
            onChangeStart={(val) => props.onChangeStart(val)}
            onChangeEnd={(val) => props.onChangeEnd(val)}
            startDate={props.startDate}
            endDate={props.endDate}
            addAssembly={props.addAssembly}
            setAddAssembly={props.setAddAssembly}
            setActiveImg={(img, images) => {
              setActiveImg(img);
              setImg(images);
            }}
            videoClicked={video_clicked}
            current_variant_key={props.current_variant_key}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    item: state.item.item,
    width: state.layout.screenWidth,
    language: state.language.language,
    countryId: state.language.countryId,
    countryUrl: state.language.countryUrl,
    cart_items_length: state.cart.cart_items_length,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDataMobile);