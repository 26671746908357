import React, { Fragment, useState } from "react";

import "../../assets/styles/header/signindropdown.scss";
import { Link } from "react-router-dom";
import { set_user } from "../../redux/actions/AuthAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { TiDocumentText } from "react-icons/ti";
import { FaRegAddressBook } from "react-icons/fa";
import { IoMdHeartEmpty } from "react-icons/io";
import { GoCreditCard } from "react-icons/go";
import { BiWallet } from "react-icons/bi";
import { MdAssignmentReturn } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { set_cart_items } from "../../redux/actions/CartAction";
import { ClipLoader } from "react-spinners";
import { apiJson } from "../../Api";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const SignInDropdown = (props) => {
  const { t } = useTranslation();

  const [signingOut, setSigningOut] = useState(false);
  var order = t("side-bar-desktop.order");
  var address = t("side-bar-desktop.addresses");
  var payment = t("side-bar-desktop.payments");
  var wallet = t("side-bar-desktop.wallet");
  var returns = t("side-bar-desktop.returns");
  var disputes = t("side-bar-desktop.disputes");
  var profile = t("side-bar-desktop.profile");
  var wishlist = t("my-account-logged-in.wishlist");
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const SIGNIN_DROPDOWN_ITEMS = [
    { id: "orders", icon: <TiDocumentText />, menuTitle: order },
    { id: "addresses", icon: <FaRegAddressBook />, menuTitle: address },
    { id: "payments", icon: <GoCreditCard />, menuTitle: payment },
    { id: "wallet", icon: <BiWallet />, menuTitle: wallet },
    { id: "returns", icon: <MdAssignmentReturn />, menuTitle: returns },
    { id: "disputes", icon: <IoWarningOutline />, menuTitle: disputes },
    { id: "profile", icon: <AiOutlineUser />, menuTitle: profile },
    { id: "wishlist", icon: <IoMdHeartEmpty />, menuTitle: wishlist },
  ];
  const items = SIGNIN_DROPDOWN_ITEMS;

  const signout = (e) => {
    e.stopPropagation();
    setSigningOut(true);
    apiJson.pushnotificationmessages.removeToken().then(() => {
    apiJson.auth.signout().then((response) => {
      if (response.success) {
        document.cookie =
          "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
        localStorage.removeItem("access_token");
        localStorage.removeItem("client");
        localStorage.removeItem("device_token")
        localStorage.removeItem("uid");
        localStorage.removeItem("guest_user_address");
        localStorage.removeItem("contact_number");
        props.set_user({ isSignedIn: false, user: {} });
        props.set_cart_items({
          cart_items: { sub_total: 0, line_items: [] },
        });
        props.handleClick();
      } else {
        setSigningOut(false);
        if (
          parseInt(response.error?.response?.status) === 404 ||
          parseInt(response.error?.response?.status) === 401
        ) {
          document.cookie =
            "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          localStorage.removeItem("access_token");
          localStorage.removeItem("client");
          localStorage.removeItem("uid");
          localStorage.removeItem("guest_user_address");
          localStorage.removeItem("contact_number");
          props.set_user({ isSignedIn: false, user: {} });
          props.set_cart_items({
            cart_items: { sub_total: 0, line_items: [] },
          });
          props.handleClick();
        } else {
          message =
            props.language === "ar" ? "هناك خطأ ما" : "something went wrong";
          showError();
          props.handleClick();
        }
      }
    });
  })
  };
  return (
    <Fragment>
      <div className="signin-dropdown">
        {items.map((item) => {
          return (
            <Link
              style={{ textDecoration: "none" }}
              to={"/" + props.countryUrl + "-" + props.language + "/" + item.id}
              key={item.id}
            >
              <div
                className="item-row d-flex justify-content align-items-center"
                key={item.id}
              >
                <div className="item-icon me-2 d-inline-flex justify-content-center align-items-center p-1">
                  {item.icon}
                </div>
                <div className="item-text ms-2 d-inline-block text-capitalize">
                  {item.menuTitle}
                </div>
              </div>
            </Link>
          );
        })}
        <div
          onClick={(e) => (!signingOut ? signout(e) : undefined)}
          className="sigout-row d-flex justify-content-center align-items-center"
        >
          <div className="signout-text mr-1">{t("signOut.so")}</div>
          {signingOut && <ClipLoader color="#000" size="15" />}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    language: state.language.language,
  };
};

const mapDispatchToProps = { set_user, set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(SignInDropdown);
