import React from "react";
import { Row } from "reactstrap";

import "../../../assets/styles/mobile/shared/productdetailcard.scss";
import { useTranslation } from "react-i18next";
import { getDeliveryDate } from "../../customer/checkout/CheckoutOrderItems";
import { connect } from "react-redux";
import moment from "moment";

const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");

const ProductDetailCard = (props) => {
  const { name, brand, price, quantity, image_path, currency, sku, variant_price, assembly, leadDays } = props;
  const { t } = useTranslation();

  const { futureDay, futureMonth, futureYear } = getDeliveryDate(leadDays);

  const updatedDate = moment(`${futureYear}-${futureMonth}-${futureDay}`).add(2, 'days');

  const updatedDay = updatedDate.date(); 
  const updatedMonth = updatedDate.format('MMMM');

  return (
    <div className="product-detail-card px-3 pt-4">
      <div className="brand">{brand}</div>
      <Row noGutters={true} className="wrapper justify-content-between">
        <div className="left">
          {name && <div className="title pt-2">{name}</div>}
          {sku && <div className="title mt-1">{sku}</div>}
          {variant_price && (
            <div className="price mt-2">
              {currency?.toUpperCase()} {parseFloat(variant_price * quantity).toFixed(2)}
            </div>
          )}
          {assembly && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="title mt-1">{t("cart-sidebar.assembly-price-included")}</span>
              <div className="price mt-1">
                {currency?.toUpperCase()}{" "}
                {parseFloat(assembly?.price).toFixed(2)}
              </div>
            </div>
          )}
          {quantity && (
            <div className="quantity mt-2">
              {t("dispute-detail-page-mob.qty")} {quantity}
            </div>
          )}
          {brand && (
            <div className="store-name mt-2">
              {t("dispute-detail-page-mob.sold-by")}{" "}
              <span className="sold-by">{brand}</span>
            </div>
          )}
          <label className="review-order-label">
            {props.cartOrder.createdAddress?.is_delivery == 1 ? (
              leadDays ? (
                props.language == "en" ? (
                  <>
                    <span className="plain-text">
                      {en_translation["my-account-logged-in"]["delivery-by"]}
                    </span>{" "}
                    <span className="active-text">{`${futureDay} - ${
                      updatedDay
                    } ${updatedMonth}`}</span>
                  </>
                ) : (
                  <>
                    <span className="plain-text">
                      {ar_translation["my-account-logged-in"]["delivery-by"]}
                    </span>{" "}
                    <span className="active-text">
                      {`${futureDay} - ${updatedDay} ${
                        updatedMonth
                      }`}
                    </span>
                  </>
                )
              ) : props.cartOrder.address?.default ? (
                props.cartOrder.address?.state == "Dubai" ? (
                  props.language == "en" ? (
                    <>
                      <span className="plain-text">
                        {
                          en_translation["my-account-logged-in"][
                            "arrive-with-in"
                          ]
                        }
                      </span>{" "}
                      <span className="active-text">
                        {en_translation["my-account-logged-in"]["arrive-24"]}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="plain-text">
                        {
                          ar_translation["my-account-logged-in"][
                            "arrive-with-in"
                          ]
                        }
                      </span>{" "}
                      <span className="active-text">
                        {ar_translation["my-account-logged-in"]["arrive-24"]}
                      </span>
                    </>
                  )
                ) : props.language == "en" ? (
                  <>
                    <span className="plain-text">
                      {en_translation["my-account-logged-in"]["arrive-with-in"]}
                    </span>{" "}
                    <span className="active-text">
                      {en_translation["my-account-logged-in"]["arrive-48"]}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="plain-text">
                      {ar_translation["my-account-logged-in"]["arrive-with-in"]}
                    </span>{" "}
                    <span className="active-text">
                      {ar_translation["my-account-logged-in"]["arrive-48"]}
                    </span>
                  </>
                )
              ) : props.language == "en" ? (
                <>
                  <span className="plain-text">
                    {en_translation["my-account-logged-in"]["arrive-with-in"]}
                  </span>{" "}
                  <span className="active-text">
                    {en_translation["my-account-logged-in"]["arrive-24-48"]}
                  </span>
                </>
              ) : (
                <>
                  <span className="plain-text">
                    {ar_translation["my-account-logged-in"]["arrive-with-in"]}
                  </span>{" "}
                  <span className="active-text">
                    {ar_translation["my-account-logged-in"]["arrive-24-48"]}
                  </span>
                </>
              )
            ) : (
              t("checkout-mobile.review-order")
            )}
          </label>
        </div>
        <div className="right">
          <div className="image-container d-flex justify-content-center mt-4">
            <img
              src={image_path}
              alt="itemimage"
              width="auto"
              height="auto"
              style={{ maxHeight: "80px", maxWidth: "80px" }}
            />
          </div>
        </div>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartOrder: state.cart.order,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ProductDetailCard);
