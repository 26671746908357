import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { apiJson } from "../../../Api";
import "../../../assets/styles/component/customer/checkout/checkoutorderitems.scss";
import QuantityCounter from "./QuantityCounter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { getDesktopImage } from "../../../utils/utils";
import AssemblyServiceModel from "../AssemblyServiceModel";
import wrench from "../../../assets/images/wrench.png";

const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");

export const getDeliveryDate = (leadDays) => {
  const futureDate = moment().add(leadDays, 'days');

  const futureDay = futureDate.date(); 
  const futureMonth = futureDate.format('MMMM');  
  const futureYear = futureDate.year();  
  
  return { futureDay, futureMonth, futureYear };
};

const CheckoutOrderItems = (props) => {
  const { t } = useTranslation();
  const [removingItem, setRemovingItem] = useState(false);
  const [updatingQuantity, setUpdatingQuantity] = useState(false);
  const qc = useRef();
  const [updatingStartDate, setUpdatingStartDate] = useState(false);
  const [updatingEndDate, setUpdatingEndDate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [addAssembly, setAddAssembly] = useState(false)

  let message = "";

  function renderDay(props, currentDate, selectedDate) {
    return (
      <td style={{ fontSize: "13px" }} {...props}>
        {currentDate.date()}
      </td>
    );
  }
  function renderMonth(props, month, year, selectedDate) {
    return <td {...props}>{month}</td>;
  }
  function renderYear(props, year, selectedDate) {
    return <td {...props}>{year % 100}</td>;
  }

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const removeItem = (id, qunatity) => {
    qunatity ? setUpdatingQuantity(true) : setRemovingItem(true);
    apiJson.cart.removeItem(id, props.countryId).then((response) => {
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });
        props.getDraftData &&
          response.data.line_items?.length > 0 &&
          props.getDraftData();

        qunatity ? setUpdatingQuantity(false) : setRemovingItem(false);
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
        qunatity ? setUpdatingQuantity(false) : setRemovingItem(false);
      } else {
        qunatity ? setUpdatingQuantity(false) : setRemovingItem(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const updateQuantity = (opt, value, id, assembly_id, assemblyAdded) => {
    setUpdatingQuantity(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            quantity: value,
            is_assembly_added: assemblyAdded || false,
            assembly_id: assemblyAdded ? assembly_id : null
          },
        },
        props.countryId
      )
      .then((response) => {
        if (response.success) {
          props.set_cart_items({ cart_items: response.data });
          props.getDraftData &&
            response.data.line_items?.length > 0 &&
            props.getDraftData();
        } else if (!response.status) {
          qc.current.setCounValue(props.order.quantity);
          message = t("errors.wrong");
          showError();
        } else {
          qc.current.setCounValue(props.order.quantity);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
        setUpdatingQuantity(false);
      });
  };

  const updateStartTime = (id, val) => {
    setUpdatingStartDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(val).toString(),
            service_type: props.order.orderable.service_type,
            ...( props.order.orderable.service_type !== "one_time" && { end_at: new Date(props.order.end_at).toString() }),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingStartDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
          props.getDraftData &&
            response.data.line_items?.length > 0 &&
            props.getDraftData();
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };
  const updateEndTime = (id, val) => {
    setUpdatingEndDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(props.order.start_at).toString(),
            end_at: new Date(val).toString(),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingEndDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
          props.getDraftData &&
            response.data.line_items?.length > 0 &&
            props.getDraftData();
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };


  const { futureDay, futureMonth, futureYear } = getDeliveryDate(props.order.orderable.product_lead_days);

  const updatedDate = moment(`${futureYear}-${futureMonth}-${futureDay}`).add(2, 'days');

  const updatedDay = updatedDate.date(); 
  const updatedMonth = updatedDate.format('MMMM');

  return (
    <div className="checkout-order-desktop">
      <Row className="nogutters order-row cart-item bg-white mx-0">
        <Col md={12} className="d-flex align-items-center p-2">
          <div className="image-container text-center">
            <img
              className="img img-fluid"
              src={getDesktopImage(
                props.order.orderable.featured_image_resized,
                props.order.orderable.featured_image
              )}
              alt=""
            />
          </div>
          <div className="d-flex flex-column w-100">
            {/* <p className="small-text m-0">{props.order.orderable_type}</p> */}
            <h4 className="m-0">
              {(props.order.orderable_type === "Product"
                ? props.order.orderable[`product_name_${props.language}`]
                : "") +
                " " +
                props.order.orderable[`name_${props.language}`]}
            </h4>
            <p className="sku m-0">
              SKU:&nbsp;
              {props.order.orderable_type === "Product"
                ? props.order.orderable.sku
                : ""}
            </p>
            <p
              className="sku m-0"
              style={{
                lineHeight: "15px"
              }}>
              {t("cart-page.sold-by")}:&nbsp;{props.order.orderable[`store_name_${props.language}`]}
            </p>

            <div
              className="text-uppercase font-weight-bold"
              style={{ textAlign: "start" }}
            >
              {props.cartOrder.createdAddress?.is_delivery == 1 ? (
                props.order.orderable.product_lead_days ? (
                  props.language == "en" ? (
                    <>
                      <span className="plain-text">
                        {
                          en_translation["my-account-logged-in"][
                            "delivery-by"
                          ]
                        }
                      </span>{" "}
                      <span className="active-text">{`${futureDay} - ${updatedDay} ${updatedMonth}`}</span>
                    </>
                  ) : (
                    <>
                      <span className="plain-text">
                        {
                          ar_translation["my-account-logged-in"][
                            "delivery-by"
                          ]
                        }
                      </span>{" "}
                      <span className="active-text">
                        {`${futureDay} - ${updatedDay} ${updatedMonth}`}
                      </span>
                    </>
                  )
                ) : props.cartOrder.address?.default ? (
                  props.cartOrder.address?.state == "Dubai" ? (
                    props.language == "en" ? (
                      <>
                        <span className="plain-text">
                          {
                            en_translation["my-account-logged-in"][
                              "arrive-with-in"
                            ]
                          }
                        </span>{" "}
                        <span className="active-text">
                          {en_translation["my-account-logged-in"]["arrive-24"]}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="plain-text">
                          {
                            ar_translation["my-account-logged-in"][
                              "arrive-with-in"
                            ]
                          }
                        </span>{" "}
                        <span className="active-text">
                          {ar_translation["my-account-logged-in"]["arrive-24"]}
                        </span>
                      </>
                    )
                  ) : props.language == "en" ? (
                    <>
                      <span className="plain-text">
                        {
                          en_translation["my-account-logged-in"][
                            "arrive-with-in"
                          ]
                        }
                      </span>{" "}
                      <span className="active-text">
                        {en_translation["my-account-logged-in"]["arrive-48"]}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="plain-text">
                        {
                          ar_translation["my-account-logged-in"][
                            "arrive-with-in"
                          ]
                        }
                      </span>{" "}
                      <span className="active-text">
                        {ar_translation["my-account-logged-in"]["arrive-48"]}
                      </span>
                    </>
                  )
                ) : props.language == "en" ? (
                  <>
                    <span className="plain-text">
                      {en_translation["my-account-logged-in"]["arrive-with-in"]}
                    </span>{" "}
                    <span className="active-text">
                      {en_translation["my-account-logged-in"]["arrive-24-48"]}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="plain-text">
                      {ar_translation["my-account-logged-in"]["arrive-with-in"]}
                    </span>{" "}
                    <span className="active-text">
                      {ar_translation["my-account-logged-in"]["arrive-24-48"]}
                    </span>
                  </>
                )
              ) : (
                <></>
                // <span className="plain-text">{t("checkout.your-order-s")}</span>
              )}
            </div>
            <p className="m-0 mt-2">
              <span className="bold-text  currency-text-cart">
                {props.currency}&nbsp;
              </span>
              <span className="bold-text price-bold-text">
                {props.order.price}
              </span>
            </p>

            {props.order.start_at && (
              <div className="m-0 d-flex flex-column">
                <div className="date-heading mb-1">
                  {props.order.orderable.service_type === "one_time" ? t("item-detail.date-and-time") : t("item-detail.start-date") + ":"}
                </div>

                <div style={{ width: "200px" }} className="mb-2">
                  <Datetime
                    onChange={(val) => {
                      if (
                        moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                          moment(new Date(props.order.end_at)).format(
                            "YYYY-MM-DDTHH:mm"
                          ) ||
                        props.order.orderable.service_type === "one_time"
                      ) {
                        if (
                          moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                            moment(new Date()).format("YYYY-MM-DDTHH:mm") ||
                          moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                            moment(new Date(props.order.start_at)).format(
                              "YYYY-MM-DDTHH:mm"
                            )
                        ) {
                          updateStartTime(props.order.id, val);
                        } else {
                          message =
                            "Start date time can not be less than current date time";
                          showError();
                        }
                      } else {
                        message = "Start date should be less than end date";
                        showError();
                      }
                    }}
                    value={new Date(props.order.start_at)}
                    renderDay={renderDay}
                    renderMonth={renderMonth}
                    renderYear={renderYear}
                    inputProps={{
                      disabled: updatingStartDate,
                    }}
                  />
                </div>
              </div>
            )}

            {props.order.end_at && props.order.orderable.service_type !== "one_time" && (
              <div className="mb-3 d-flex flex-column">
                <div className="date-heading mb-1">
                  {t("item-detail.end-date") + ":"}
                </div>

                <div style={{ width: "200px" }} className="mb-2">
                  <Datetime
                    onChange={(val) => {
                      if (
                        moment(new Date(props.order.start_at)).format(
                          "YYYY-MM-DDTHH:mm"
                        ) < moment(new Date(val)).format("YYYY-MM-DDTHH:mm")
                      ) {
                        updateEndTime(props.order.id, val);
                      } else {
                        message = "End date should be greater than start date";
                        showError();
                      }
                    }}
                    value={new Date(props.order.end_at)}
                    renderDay={renderDay}
                    renderMonth={renderMonth}
                    renderYear={renderYear}
                    inputProps={{
                      disabled: updatingEndDate,
                    }}
                  />
                </div>
              </div>
            )}
            {props.order.orderable?.assembly && (
              <AssemblyServiceModel
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                addAssembly={addAssembly}
                setAddAssembly={setAddAssembly}
                assemblyPrice={
                  props.order.orderable.assembly.price + " " + props.currency
                }
                showButton={true}
                onUpdate={(isAdd) =>
                  updateQuantity(
                    "",
                    props.order.quantity || 1,
                    props.order.id,
                    props.order.orderable?.assembly.id,
                    isAdd
                  )
                }
              />
            )}
            {props.order.orderable?.assembly &&
              (props.order.assembly ? (
                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderTop: "1px solid #e5e9f2", 
                    padding: "5px 0px",
                    marginBottom: "5px",
                    borderBottom: "1px solid #e5e9f2", 
                  }}
                >
                  <div>
                    <span className="small-text mr-1">
                    <img src={wrench} height={16} width={16} />{" "}
                      {t("cart-sidebar.assembly-price-included")}
                    </span>

                    <button
                      type="button"
                      className="bg-white d-flex pointer align-items-center mb-1"
                      onClick={() =>
                        updateQuantity(
                          "",
                          props.order.quantity || 1,
                          props.order.id,
                          null,
                          false
                        )
                      }
                    >
                      <MdDelete className="mb-1" />
                      <span className="small-text ml-1">
                        {t("cart-sidebar.remove-assembly")}
                      </span>
                    </button>
                  </div>
                  <span style={{ fontWeight: 600, fontSize: "0.94rem" }}>
                    {props.currency}{" "}
                    {props.order?.assembly?.price}
                  </span>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="bg-white d-flex pointer align-items-center mb-1"
                    onClick={() => setIsOpen(true)}
                  >
                    <span className="small-text mr-1">
                      <img src={wrench} height={16} width={16} />{" "}
                      {t("cart-sidebar.add-assembly")}
                    </span>
                  </button>
                </div>
              ))}

            <div className="d-flex align-items-center justify-content-between">
              {/* <button
                onClick={() => removeItem(props.order.id)}
                type="button"
                className="bg-white d-flex align-items-center mb-0"
              >
                <MdDelete className="mb-0" />
                <span className="small-text ml-1">
                  {removingItem ? (
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <ClipLoader color="#000" loading size={20} />
                    </div>
                  ) : (
                    t("checkout.btn-remove")
                  )}
                </span>
              </button> */}
              {props.order.quantity && (
                <QuantityCounter
                  ref={qc}
                  addValue={(value) => updateQuantity("add", value, props.order.id)}
                  subValue={(value) => updateQuantity("sub", value, props.order.id)}
                  value={props.order.quantity}
                  loading={updatingQuantity}
                  isQunatityUpdatedable={!!props.order?.assembly?.id}
                  remove={() => removeItem(props.order.id, true)}
                />
              )}
            </div>
          </div>
        </Col>
        {/* <Col
          md={4}
          className="d-flex flex-column align-items-end pt-3 padding-dir"
        >
          <p className="mb-0">
            <span className="small-text  currency-text-cart">
              {props.currency}
            </span>
            <span className="bold-text price-bold-text">
              {props.order.price}
            </span>
          </p>
          <p className="margin-bottom-6 remove-text invisible">
            <span className="small-text mr-2 ">QTY</span>
            <span className="small-text">{props.order.quantity}</span>
          </p>
          {props.order.quantity && (
            <QuantityCounter
              ref={qc}
              addValue={(value) => updateQuantity("add", value, props.order.id)}
              subValue={(value) => updateQuantity("sub", value, props.order.id)}
              value={props.order.quantity}
              loading={updatingQuantity}
              remove={() => removeItem(props.order.id, true)}
            />
          )}
        </Col> */}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart_items: state.cart.cart_items,
    language: state.language.language,
    countryId: state.language.countryId,
    cartOrder: state.cart.order,
  };
};
const mapDispatchToProps = { set_cart_items };
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOrderItems);
