import React from "react";
import { Redirect, useHistory, Route, Switch } from "react-router-dom";
import ShippingAddress from "./components/customer/ShippingAddress";
import AddAddressForm from "./pages/AddAddressForm";
import SelectShipping from "./pages/SelectShipping";
import VerifyPhoneNumber from "./pages/VerifyPhoneNumber";
import "./assets/styles/general.scss";
import OrderPage from "./pages/OrderPage";
import WalletPage from "./pages/customer/account/WalletPage";
import PaymentPage from "./pages/customer/account/PaymentPage";
import ProfilePage from "./pages/customer/account/ProfilePage";
import OrderDetailsPage from "./pages/customer/account/OrderDetailsPage";
import ReturnsPage from "./pages/customer/account/ReturnsPage";
import CustomerProfileAddressPage from "./pages/customer/account/CustomerProfileAddressPage";
import CheckoutPage from "./pages/customer/checkout/CheckoutPage";
import CheckoutMob from "./pages/mobile/CheckoutMob";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomerProfileMapPage from "./pages/customer/account/CustomerProfileMapPage";
import CustomerAddressMobilePage from "./pages/mobile/CustomerAddressPage";
import AddAddressMobilePage from "./pages/mobile/AddAddressPage";
import MyAccountLoggedInMob from "./pages/mobile/MyAccountLoggedInMob";
import PaymentDataMob from "./pages/mobile/PaymentDataMob";
import ThankYouPageMobile from "./pages/mobile/ThankYouPageMobile";
import ProfileMobPage from "./pages/mobile/customer/ProfileMobilePage";
import WalletPageMob from "./pages/mobile/WalletPageMob";

import DisputesPage from "./pages/customer/account/DisputesPage";
import DisputeDetailPage from "./pages/customer/account/DisputeDetailPage";
import { apiJson } from "./Api";
import ReviewPage from "./pages/customer/account/ReviewPage";
import OrderItemsList from "./pages/customer/account/OrderItemsList";
import PaymentAddNewCard from "./pages/mobile/PaymentAddNewCard";
import ChangePasswordMobile from "./pages/mobile/ChangePasswordMobile";
import OrderItemsListForRefund from "./pages/mobile/OrderItemsListForRefund";
import ItemAndReasonForRefund from "./pages/mobile/itemAndReasonForRefund";
import ReviewPageMob from "./pages/mobile/ReviewPageMob";

import ProtectedRoutes from "./components/user/ProtectedRoutes";
import OrdePlaced from "./pages/customer/checkout/OrderPlaced";
import DisputeDeatilsPageMob from "./pages/mobile/DisputeDetailsPageMob";
import DisputePageMob from "./pages/mobile/DisputePageMob";
import GuestUserRoutes from "./components/user/GuestUserRoutes";
import ProfileEditMobilePage from "./pages/mobile/customer/ProfileEditMobilePage";
import WishlistMobPage from "./pages/mobile/WishlistMobilePage";
import { set_user } from "./redux/actions/AuthAction";
import WishlistPage from "./pages/cart/WishlistPage";
import ProductHomePage from "./components/pages/ProductHomePage";
import MobileNotifications from "./components/mobile/notifications/MobileNotifications";

function Routes(props) {
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    localStorage.getItem("access_token")
      ? apiJson.auth.validateToken().then((response) => {
          if (response.success) {
            localStorage.setItem(
              "access_token",
              response.headers["access-token"]
            );
            localStorage.setItem("client", response.headers.client);
            localStorage.setItem("uid", response.headers.uid);
            props.set_user({
              isSignedIn: true,
              user: response.data.data,
            });
          } else {
            if (parseInt(response.status) === 401) {
              props.set_user({ isSignedIn: false, user: {} });
              localStorage.removeItem("access_token");
              localStorage.removeItem("client");
              localStorage.removeItem("uid");
              document.cookie =
                "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
              setRedirect(true);
            }
          }
        })
      : (document.cookie =
          "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC");
  }, [history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <GuestUserRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/checkout"}
        component={props.width === "sm" ? CheckoutMob : CheckoutPage}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/wishlist"}
        component={props.width === "sm" ? WishlistMobPage : WishlistPage}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/returns"}
        component={ReturnsPage}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language +  (props.width === "sm" ? "/notifications" : "/") }
        component={MobileNotifications}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/profile"}
        component={props.width === "sm" ? ProfileMobPage : ProfilePage}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/addresses"}
        component={
          props.width === "sm"
            ? CustomerAddressMobilePage
            : CustomerProfileAddressPage
        }
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/payments"}
        component={props.width === "sm" ? PaymentDataMob : PaymentPage}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/wallet"}
        component={props.width === "sm" ? WalletPageMob : WalletPage}
      />

      <ProtectedRoutes
        exact
        path={
          "/" + props.countryUrl + "-" + props.language + "/order/details/:id"
        }
        component={OrderDetailsPage}
      />

      <GuestUserRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/ship"}
        component={ShippingAddress}
      />

      <GuestUserRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/address"}
        component={props.width === "sm" ? AddAddressMobilePage : AddAddressForm}
      />

      <GuestUserRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/phone"}
        component={VerifyPhoneNumber}
      />

      <GuestUserRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/select-address"}
        component={
          props.width === "sm" ? CustomerAddressMobilePage : SelectShipping
        }
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/orders"}
        component={OrderPage}
      />

      <ProtectedRoutes
        exact
        path={
          "/" + props.countryUrl + "-" + props.language + "/add-new-payment"
        }
        component={PaymentAddNewCard}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/profile-map"}
        component={CustomerProfileMapPage}
      />

      <ProtectedRoutes
        exact
        path={
          "/" + props.countryUrl + "-" + props.language + "/my-account-li-mob"
        }
        component={MyAccountLoggedInMob}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/pw-change-mob"}
        component={
          props.width === "sm" ? ChangePasswordMobile : ProductHomePage
        }
      />

      <ProtectedRoutes
        exact
        path={
          "/" + props.countryUrl + "-" + props.language + "/edit-profile-mobile"
        }
        component={
          props.width === "sm" ? ProfileEditMobilePage : ProductHomePage
        }
      />

      <ProtectedRoutes
        exact
        path={
          "/" + props.countryUrl + "-" + props.language + "/dispute/detail/:id"
        }
        component={props.width === "sm" ? DisputePageMob : DisputeDetailPage}
      />

      <ProtectedRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/disputes"}
        component={props.width === "sm" ? DisputeDeatilsPageMob : DisputesPage}
      />

      <ProtectedRoutes
        exact
        path={
          "/" + props.countryUrl + "-" + props.language + "/create/review/:id"
        }
        component={props.width === "sm" ? ReviewPageMob : ReviewPage}
      />

      <ProtectedRoutes
        exact
        path={
          "/" +
          props.countryUrl +
          "-" +
          props.language +
          "/order/itemslist/:name"
        }
        component={
          props.width === "sm" ? OrderItemsListForRefund : OrderItemsList
        }
      />

      <ProtectedRoutes
        exact
        path={
          "/" + props.countryUrl + "-" + props.language + "/create/:name/:id"
        }
        component={ItemAndReasonForRefund}
      />

      <GuestUserRoutes
        exact
        path={"/" + props.countryUrl + "-" + props.language + "/thank-you"}
        component={props.width === "sm" ? ThankYouPageMobile : OrdePlaced}
      />

      <Route
        render={() => (
          <Redirect
            to={{
              pathname: "/" + props.countryUrl + "-" + props.language + "/",
            }}
          />
        )}
      />
      {redirect && <Redirect push to="/login" />}
    </Switch>
  );
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    direction: state.language.direction,
    language: state.language.language,
    countryId: state.language.countryId,
    country: state.language.country,
    coutriesList: state.language.coutriesList,

    countryUrl: state.language.countryUrl,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  set_user,
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
