import React, { Fragment, useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../../../assets/styles/customer/cart/cartordersummary.scss";
import "./../../../assets/styles/customer/cart/cartpage.scss";
import "../../../assets/styles/component/customer/checkout/orderitems.scss";
import "../../../assets/styles/component/customer/checkout/quantitycounter.scss";
import { apiJson } from "../../../Api";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { connect } from "react-redux";
import CartOrderSummary from "./CartOrderSummary";
import CartEmptyPage from "./CartEmptyPage";
import OrderItems from "../checkout/OrderItems";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import YelloColorImage from "../../../assets/images/homepage/YelloColorImage.png";

const CartDataState = (props) => {
  const [clearing, setClearing] = useState(false);
  const [flag, setFlag] = useState(false);
  const { t } = useTranslation();
  const [dataChanged, setDataChanged] = useState(false);
  const [couponExpired, setCoupopnExpired] = useState(false);
  const [showCoupons, setShowCoupons] = useState(false);
  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const clearCart = () => {
    setClearing(true);
    apiJson.cart.clearCartItems(props.countryId).then((response) => {
      setClearing(false);
      if (response.success) {
        props.set_cart_items({
          cart_items: {
            user_id: props.cart.user_id,
            sub_total: 0,
            line_items: [],
          },
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const addCoupon = (coupon) => {
    setFlag(true);
    apiJson.cart.addCoupon(coupon, props.countryId).then((response) => {
      setFlag(false);
      setShowCoupons(false)
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : t("cart-page.wrong-coupon");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const removeCoupon = () => {
    setFlag(true);
    apiJson.cart.removeCoupon(props.countryId).then((response) => {
      setFlag(false);
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  useEffect(() => {
    props.cart_items.cart_data_changed &&
      setDataChanged(props.cart_items?.cart_data_changed);

    props.cart_items.cart_data_changed &&
      apiJson.cart.updateCartReset(props.countryId);
    props.cart_items.cart_data_changed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          cart_data_changed: false,
        },
      });
  }, [props.cart_items.cart_data_changed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.cart_items.expired_coupon_removed &&
      setCoupopnExpired(props.cart_items?.expired_coupon_removed);

    props.cart_items.expired_coupon_removed &&
      apiJson.cart.discountReset(props.countryId);
    props.cart_items.expired_coupon_removed &&
      props.set_cart_items({
        cart_items: {
          ...props.cart_items,
          expired_coupon_removed: false,
        },
      });
  }, [props.cart_items.expired_coupon_removed]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {props.cart?.line_items?.length > 0 ? (
        <div className="cart-desktop">
          <Row className="cart-data-state pt-3 pb-2 no-gutters">
            <Col
              lg={8}
              className="no-gutters orderitems-and-wishlist no-gutters"
            >
              <Col lg={12} className=" no-gutters cart-left">
                <div className=" cart-items mt-2">
                  {t("cart-page.cart")}
                  <span className="items-text">
                    {props.cart?.line_items?.length} {t("items.items")}
                  </span>
                </div>
                <div md={12} className=" cart-product-image-area mt-2">
                  {props.primaryBanners.length > 0 &&
                  props.primaryBanners[0].image ? (
                    <>
                      {props.primaryBanners[0]?.redirects_to_link ? (
                        <a href={props.primaryBanners[0]?.redirects_to_link}>
                          <img
                            src={
                              props.primaryBanners[0].image || YelloColorImage
                            }
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </a>
                      ) : (
                        <img
                          src={props.primaryBanners[0].image || YelloColorImage}
                          width="100%"
                          height="100%"
                          alt=""
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={YelloColorImage}
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  )}
                </div>
                <div className="col-md-12">
                  <div className="title text-uppercase fw-bold mt-4 mb-3">
                    {t("checkout.your-order")}
                  </div>
                  {props.cart.line_items?.map((item) => (
                    <OrderItems
                      currency={props.currency}
                      order={item}
                      className="order-row "
                      total={props.cart_items.sub_total}
                      updateTotal={(val) =>
                        props.set_cart_items({
                          cart_items: {
                            ...props.cart_items,
                            sub_total: val,
                          },
                        })
                      }
                      key={item.id}
                    />
                  ))}
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <Link
                    to={"/" + props.countryUrl + "-" + props.language + "/"}
                  >
                    <button
                      type="button"
                      className="cart-continue-shopping-button"
                    >
                      {t("cart-page.continue-shop-btn")}
                    </button>
                  </Link>
                  <button
                    onClick={() => clearCart()}
                    type="button"
                    className="pointer remove-all-button"
                  >
                    {clearing ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <ClipLoader color="#000" loading size={20} />
                      </div>
                    ) : (
                      t("cart-page.remove-all-btn")
                    )}
                  </button>
                </div>
                {dataChanged && (
                  <div className="data-change">
                    <div className="w-90 ">
                      <div>{t("data-changed.quantity-changed")}</div>
                      <div className="mt-1">
                        {t("data-changed.prices-changed")}
                      </div>
                      <div className="mt-1">
                        {t("data-changed.review-cart")}
                      </div>
                    </div>
                    <div className="w-8  dismiss-border dismiss m-auto">
                      <span
                        className="pointer"
                        onClick={() => setDataChanged(false)}
                      >
                        {" "}
                        {t("coupon-expire.dismiss")}
                      </span>
                    </div>
                  </div>
                )}
                {couponExpired && (
                  <div className="data-change">
                    <div className="w-90 ">
                      <div>{t("coupon-expire.coupon-expired")}</div>

                      <div className="mt-1">
                        {t("coupon-expire.review-cart")}
                      </div>
                    </div>
                    <div className="w-8  dismiss-border dismiss m-auto">
                      <span
                        className="pointer"
                        onClick={() => setCoupopnExpired(false)}
                      >
                        {" "}
                        {t("coupon-expire.dismiss")}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Col>
            <Col lg={4}>
              <Row className="ordersummary-and-productarea no-gutters">
                <Col md={12} className="justify-content-center">
                  <CartOrderSummary
                    sub_total={props.cart_items.sub_total}
                    items={props.cart.line_items?.length}
                    coupons={props.cart_items.coupons}
                    currency={props.currency}
                    applyCoupon={(val) => addCoupon(val)}
                    removeCoupon={() => removeCoupon()}
                    couponFlag={flag}
                    discount={props.cart_items.discount}
                    couponCode={props.cart_items.coupon_code}
                    showCoupons={showCoupons}
                    setShowCoupons={setShowCoupons}
                    total={
                      parseFloat(props.cart_items.sub_total) -
                      (props.cart_items.discount
                        ? parseFloat(props.cart_items.discount)
                        : 0)
                    }
                  />
                </Col>
                <Col md={12} className="cart-product-area ">
                  {props.secondaryBanners[0]?.image && (
                    <>
                      {props.secondaryBanners[0]?.redirects_to_link ? (
                        <a href={props.secondaryBanners[0]?.redirects_to_link}>
                          <img
                            src={props.secondaryBanners[0].image}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </a>
                      ) : (
                        <img
                          src={props.secondaryBanners[0].image}
                          width="100%"
                          height="100%"
                          alt=""
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <CartEmptyPage />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    cart_items: state.cart.cart_items,
    primaryBanners: state.cart.primaryBanners,
    secondaryBanners: state.cart.secondaryBanners,
    wish_list: state.cart.wish_list,
    country: state.language.country,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(CartDataState);
