import React, { useEffect, useState } from "react";
import ItemsHeader from "./ItemsHeader";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ProductCard from "../../product/ProductCard";
import { set_all_items } from "../../../redux/actions/AllItemsAction";
import { connect } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import ProductCardMobile from "../../mobile/Home/ProductCardMobile";
import "../../../assets/styles/component/customer/AllItems/ItemsMain.scss";
import {
  getDesktopImage,
  getMobileImage,
  replaceAt,
} from "../../../utils/utils";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";

const OffersMain = (props) => {
  const { t } = useTranslation();
  const [forceRender, setForceRender] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const history = useHistory();
  const location = useLocation();
  let { type } = useParams();
  type = "products";

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handlePageChange = (pageNumber) => {
    window.scroll(0, 0);
    props.set_loading(true);

    apiJson.homepage.getOffersData(props.countryId, pageNumber.selected + 1, props.recordPerPage).then(async (response) => {
      props.set_loading(false);
      if (response.success) {
        //temporarily added
        let final_products = [];

        if (props.type === "products") {
          response.data.products.forEach(product => {
            if (product.variants) {
              if (product.variants[0] != null) {
                final_products = final_products.concat(product);
              }
            }
          });
  
          final_products.forEach(product => {
            product.variants = product.variants.sort((el1,el2) => el1.price.localeCompare(el2.price, undefined, {numeric: true}));
          });
        }
        //temporarily added

        props.set_all_items({
          page: pageNumber.selected + 1,
          // items:
          //   props.type === "products"
          //     ? response.data.products
          //     : response.data.services,
          items:
            props.type === "products"
              ? final_products
              : response.data.services,
          pagination: response.data.pagination,
        });
        props.set_loading(false);
      } else if (!response.status) {
        props.set_loading(false);
        // eslint-disable-next-line
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        props.set_loading(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  useEffect(() => {
    if (props.data) {
      if (firstRender) {
        setFirstRender(false);

        return;
      }
    }
    // eslint-disable-next-line
  }, [
    props.recordPerPage
  ]);

  useEffect(() => {
    setFirstRender(true);
  }, [location.state?.brand, type]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setForceRender(!forceRender);
  }, [props.sortVal]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="item-main-desktop">
      {/* <div className="w-100">
        <ItemsHeader
          type={props.type}
          sortBy={props[`sortBy_${props.language}`]}
          display={props[`display_${props.language}`]}
          filterBy={props.filter_by}
          title={props.title}
        />
      </div> */}

        <div className="items-body row no-gutters">
          {props.items?.map((value) => (
            <React.Fragment key={value.id}>
              {value.variants ? (
                <>
                  <ProductCard
                    key={value.id + "prod"}
                    product={{
                      id: value.id,
                      line_item_id: value.variants[0].id,
                      img: getDesktopImage(
                        value.variants[0].featured_image_resized,
                        value.variants[0].featured_image
                      ),
                      description_en: value[`name_en`],
                      category_en: value.category_title_en,
                      type: "products",
                      slug: value.slug,
                      storeLogo: value.store_logo,
                      description: value[`name_${props.language}`],
                      price_after_discount: parseFloat(
                        value.variants[0].discounted_price
                      ),
                      price_before_discount: parseFloat(value.variants[0].price),
                      rating: parseInt(value.average_rating),
                      count: value.reviews_count,
                      wishlisted: value.variants[0].wishlisted,
                      is_inquiry: value.is_inquiry
                    }}
                  />
                  <div className="large-display-none">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${value.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${value.slug}`,
                        state: {
                          id: value.id,
                          type: "products",
                        },
                      }}
                    >
                      <div>
                        <ProductCardMobile
                          key={value.id + "prod"}
                          img={getMobileImage(
                            value.variants[0].featured_image_resized,
                            value.variants[0].featured_image
                          )}
                          wishlisted={value.variants[0].wishlisted}
                          type="products"
                          storeLogo={value.store_logo}
                          id={value.variants[0].id}
                          name={value[`name_${props.language}`]}
                          data={value.variants[0]}
                          rating={value.average_rating}
                          reviews={value.reviews_count}
                          is_inquiry={value.is_inquiry}
                          category_en={value.category_title_en}
                          slug={value.slug}
                        />
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <ProductCard
                    key={value.id + "ser"}
                    product={{
                      id: value.id,
                      img: getDesktopImage(
                        value.featured_image_resized,
                        value.featured_image
                      ),
                      type: "services",

                      slug: value.slug,
                      service_type: value.service_type,
                      description: value[`name_${props.language}`],
                      description_en: value[`name_en`],
                      category_en: value.category_title_en,
                      price_after_discount: parseFloat(value.discounted_price),
                      price_before_discount: parseFloat(value.price),
                      rating: parseInt(value.average_rating),
                      count: value.reviews_count,
                      wishlisted: value.wishlisted,
                      storeLogo: value.store_logo,
                      is_inquiry: value.is_inquiry
                    }}
                  />
                  <div className="large-display-none">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${value.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${value.slug}`,
                        state: {
                          id: value.id,
                          type: "services",
                        },
                      }}
                    >
                      <div>
                        <ProductCardMobile
                          key={value.id + "ser"}
                          img={getMobileImage(
                            value.featured_image_resized,
                            value.featured_image
                          )}
                          wishlisted={value.wishlisted}
                          type="services"
                          id={value.id}
                          name={value[`name_${props.language}`]}
                          data={value}
                          storeLogo={value.store_logo}
                          is_inquiry={value.is_inquiry}
                          category_en={value.category_title_en}
                          slug={value.slug}
                        />
                      </div>
                    </Link>
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
        
      <div
        className="d-flex justify-content-center mb-4"
        style={{ direction: "ltr", maxWidth: "98%" }}
      >
        {props?.pagination?.total_pages > 1 && props.data && (
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={parseInt(props?.pagination?.total_pages)}
            forcePage={parseInt(props?.pagination?.page) - 1}
            onPageChange={handlePageChange}
            pageRangeDisplayed={props.width === "sm" ? 1 : 2}
            marginPagesDisplayed={props.width === "sm" ? 1 : 2}
            containerClassName="pagination"
            pageClassName="pl-3 pr-3 pt-2 pb-2"
            activeClassName={
              "ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white pl-3 pr-3 pt-2 pb-2"
            previousClassName="bg-white pl-3 pr-3 pt-2 pb-2"
          />
        )}
        {props?.pagination?.total_pages < 2 &&
          props.data &&
          props?.items?.length > 0 && (
            <div
              className="ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white pl-3 pr-3 pt-2 pb-2
  "
            >
              <span>1</span>
            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    seller: state.allItems.seller,
    recordPerPage: state.allItems.recordPerPage,
    page: state.allItems.page,
    rating: state.allItems.rating,
    minPrice: state.allItems.minPrice,
    price_high_to_low: state.allItems.price_high_to_low,
    price_low_to_high: state.allItems.price_low_to_high,
    selectedBrands: state.allItems.selectedBrands,
    selectedStores: state.allItems.selectedStores,
    new_arrivals: state.allItems.new_arrivals,
    go: state.allItems.go,
    maxPrice: state.allItems.maxPrice,
    brand: state.allItems.brand,
    filter_by: state.allItems.filter_by,
    sortBy_en: state.allItems.sortBy_en,
    display_en: state.allItems.display_en,
    sortBy_ar: state.allItems.sortBy_ar,
    display_ar: state.allItems.display_ar,
    items: state.allItems.items,
    sortVal: state.allItems.sortVal,
    width: state.layout.screenWidth,
    selectedCat: state.allItems.selectedCat,
    originalItems: state.allItems.originalItems,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    load: state.layout.loading,
    countryId: state.language.countryId,
    pagination: state.allItems.pagination,
  };
};

const mapDispatchToProps = {
  set_loading,
  set_all_items,
};
export default connect(mapStateToProps, mapDispatchToProps)(OffersMain);
