import { type } from "../../types/types";

export default function reducer(
  state = { count: 0, notifications: [] },
  action
) {
  switch (action.type) {
    case type.SET_NOTIFICATIONS:
      return { ...state, ...action.notifications };
    case type.SET_NOTIFICATIONS_COUNT:
      return { ...state, ...action.count };
    default:
      return state;
  }
}

