import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Modal
} from "react-bootstrap";
import { useOutsideAlerter } from "../../../utils/CustomHooks";
import "../../../assets/styles/component/customer/itemDetail/ItemCmp.scss";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import CartSidebar from "../cart/CartSidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { apiJson } from "../../../Api";
import { connect } from "react-redux";
import { set_single_item } from "../../../redux/actions/SingleItemAction";
import DateAndTimePickers from "./DateAndTimePickers";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import ReactImageMagnify from "react-image-magnify";
import { set_cart_items } from "../../../redux/actions/CartAction";
import NotifyUser from '../../../components/product/NotifyUser';
import AssemblyServiceModel from "../AssemblyServiceModel";


const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");

function ItemCmp(props) {
  const sideCartRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [active, setActive] = useState(
    props.data.variants ? parseInt(props.data.variants[0].id) : 0
  );
  const variantList = props.data.variants;
  const { t } = useTranslation();
  const [showCart, setShowCart] = useState(false);
  useOutsideAlerter(sideCartRef, setShowCart);
  const [serviceModalIsOpen, setServiceModalIsOpen] = useState(false);
  const [showNotifyModel, setShowNotifyModel] = useState(false);
  const [quantityVal, setQuantityVal] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [checkout_redirect, setCheckoutRedirect] = useState(false);

  const [quantity, setQuantity] = useState("1");
  const [adding, setAdding] = useState(false);
  const [buyNowClicked, setBuyNowClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date(Date.now() + 3600 * 1000 * 24).setSeconds(0))
  );
  const [addAssembly, setAddAssembly] = useState(() => !!props.item?.assembly?.id)
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.cart_items_length === 0) {
      setShowCart(false);
    }
  }, [props.cart_items_length]);

  useEffect(() => {
    props.data.order_quantity &&
      setQuantityVal(
        props.data.order_quantity
          ? Array.from({ length: props.data.order_quantity }, (_, i) => i + 1)
          : undefined
      );
    props.data.order_quantity && setQuantity("1");
  }, [props.data.order_quantity]);

  useEffect(() => {
    if (props.current_variant_key) {
      setActive(props.current_variant_key);
      handleChange({ value: props.current_variant_key });
    }
  }, [props.current_variant_key]);

  const handleChange = (e) => {
    props.data.variants.map((variant) =>
      variant.id === e.value
        ?
        variant.images ?
          props.setActiveImg(variant.featured_image, [
            {
              id: variant.id + "_" + 0,
              src: variant.featured_image,
              active: true,
            },
            ...variant.images?.map((ele, index) => {
              return {
                id: variant.id + "_" + (index + 1),
                src: ele,
                active: false,
              };
            }),
          ])
          :
          props.setActiveImg(variant.featured_image, [
            {
              id: variant.id + "_" + 0,
              src: variant.featured_image,
              active: true,
            }
          ])
        : undefined
    );
    let q = props.data.variants
      .map((variant) =>
        variant.id === e.value
          ? parseInt(variant.quantity) < 1
            ? -1
            : variant.quantity
          : undefined
      )
      .filter((variant) => variant !== undefined)[0];

    props.set_single_item({
      item: {
        ...props.item,
        variantName_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.name_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        description_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant[`description_en`] : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        description_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant[`description_ar`] : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        sku: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant[`sku`] : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        variantName_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.name_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        variantId: props.data.variants
          .map((variant) => (variant.id === e.value ? variant.id : undefined))
          .filter((variant) => variant !== undefined)[0],
        price_before_discount: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.price : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        order_quantity: q === -1 ? undefined : q,

        price_after_discount: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.discounted_price : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        wishlisted: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.wishlisted : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        order_id: props.data.variants
          .map((variant) => (variant.id === e.value ? variant.id : undefined))
          .filter((variant) => variant !== undefined)[0],

        benefits_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.benefits_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        benefits_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.benefits_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        features_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.features_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        features_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.features_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        how_to_use_ar: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.how_to_use_ar : undefined
          )
          .filter((variant) => variant !== undefined)[0],
        how_to_use_en: props.data.variants
          .map((variant) =>
            variant.id === e.value ? variant.how_to_use_en : undefined
          )
          .filter((variant) => variant !== undefined)[0],
      },
    });
  };

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorTop = () => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const moveToWishlist = () => {
    setLoading(true);
    apiJson.cart
      .moveToWishlist(
        {
          wishlist_item: {
            orderable_type:
              props.data.type === "Product" ? "Variant" : "Service",
            orderable_id: props.data.order_id,
          },
        },
        props.countryId
      )
      .then((response) => {
        setLoading(false);
        if (response.success) {
          if (response.data.added) {
            props.set_single_item({
              item: {
                ...props.item,
                wishlisted: !props.data.wishlisted,
                variants: props.data.variants
                  ? props.data.variants.map((variant) =>
                    variant.id === props.data.order_id
                      ? { ...variant, wishlisted: !variant.wishlisted }
                      : variant
                  )
                  : false,
              },
            });
          } else {
            props.set_single_item({
              item: {
                ...props.item,
                wishlisted: !props.data.wishlisted,
                variants: props.data.variants
                  ? props.data.variants.map((variant) =>
                    variant.id === props.data.order_id
                      ? { ...variant, wishlisted: !variant.wishlisted }
                      : variant
                  )
                  : false,
              },
            });
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  const addToCart = () => {
    if (props.data.product_type == "wholesale") {
      if (props.data.qty3range_to) {
        if (parseInt(quantity) > parseInt(props.data.qty3range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.data.qty3range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod();
        }
      }
      else if (props.data.qty2range_to) {
        if (parseInt(quantity) > parseInt(props.data.qty2range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.data.qty2range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod();
        }
      }
      else {
        if (parseInt(quantity) > parseInt(props.data.qty1range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.data.qty1range_to + ".";
          showError();
        }
        else {
          addToCartApiMethod();
        }
      }
    }
    else {
      addToCartApiMethod();
    }
  };

  const buyNow = () => {
    if (props.data.product_type == "wholesale") {
      if (props.data.qty3range_to) {
        if (parseInt(quantity) > parseInt(props.data.qty3range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.data.qty3range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod();
        }
      }
      else if (props.data.qty2range_to) {
        if (parseInt(quantity) > parseInt(props.data.qty2range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.data.qty2range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod();
        }
      }
      else {
        if (parseInt(quantity) > parseInt(props.data.qty1range_to)) {
          message = "This product is not available in this quantity. Maximum available stock is " + props.data.qty1range_to + ".";
          showError();
        }
        else {
          buyNowApiMethod();
        }
      }
    }
    else {
      buyNowApiMethod();
    }
  };

  const buyNowApiMethod = () => {
    setBuyNowClicked(true);
    apiJson.cart
      .addToCart(
        props.data.product_type == "wholesale" ?
          {
            line_item: {
              quantity: parseInt(quantity.label ? quantity.label : quantity),
              orderable_type: "Variant",
              orderable_id: props.data.variantId,
              product_type: "wholesale"
            }
          }
          :
          props.data.type === "Product"
            ? {
              line_item: {
                quantity: parseInt(quantity.label ? quantity.label : quantity),
                orderable_type: "Variant",
                orderable_id: props.data.variantId,
                is_assembly_added: addAssembly,
                ...(addAssembly && { assembly_id: props.item.assembly.id })
              },
            }
            : {
              line_item: {
                orderable_type: "Service",
                orderable_id: props.data.order_id,
                start_at: new Date(startDate).toString(),
                service_type: props.data?.service_type,
                ...(props.data?.service_type !== "one_time" && { end_at: new Date(endDate).toString() })
              },
            },
        props.countryId
      )
      .then((response) => {
        setBuyNowClicked(false);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            // setShowCart(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            // setShowCart(true);
          }

          props.set_cart_items({ cart_items: response.data })

          // move to checkout
          if (props.auth) {
            apiJson
              .select.getDefault()
              .then(resp => {
                if (resp.success) {
                  if (resp.data.length > 0) {
                    const request = {
                      order: {
                        address_id: resp.data[0].id,
                        is_delivery: 1
                      },
                    };

                    apiJson.select
                      .varifyAdress(request, props.countryId)
                      .then((response) => {
                        if (response.success) {
                          props.set_cart_items({
                            order: {
                              address: resp.data[0],
                              id: response.data.id,
                              createdAddress: response.data,
                            },
                          });
                          setCheckoutRedirect({
                            value: true,
                            path:
                              "/" + props.countryUrl + "-" + props.language + "/checkout",
                          });
                        } else if (!response.status) {
                          message = t("errors.wrong");
                          showError();
                        } else {
                          if (response.status >= 400 && response.status < 500) {
                            message = response.message
                              ? response.message
                              : response.errors
                                ? response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = response.message
                              ? response.message !== ""
                                ? response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                      });
                  }
                  else {
                    props.set_cart_items({
                      order: {
                        address: null,
                        id: null,
                        createdAddress: null,
                      },
                    });
                    setCheckoutRedirect({
                      value: true,
                      path:
                        "/" + props.countryUrl + "-" + props.language + "/checkout",
                    });
                  }
                }
              });
          }
          else {
            props.set_cart_items({
              order: {
                address: null,
                id: null,
                createdAddress: null,
              },
            });
            setCheckoutRedirect({
              value: true,
              path:
                "/" + props.countryUrl + "-" + props.language + "/checkout",
            });
          }
          // move to checkout end

        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }

  const addToCartApiMethod = () => {
    setAdding(true);
    apiJson.cart
      .addToCart(
        props.data.product_type == "wholesale" ?
          {
            line_item: {
              quantity: parseInt(quantity.label ? quantity.label : quantity),
              orderable_type: "Variant",
              orderable_id: props.data.variantId,
              product_type: "wholesale"
            }
          }
          :
          props.data.type === "Product"
            ? {
              line_item: {
                quantity: parseInt(quantity.label ? quantity.label : quantity),
                orderable_type: "Variant",
                orderable_id: props.data.variantId,
                is_assembly_added: addAssembly,
                ...(addAssembly && { assembly_id: props.item.assembly.id })
              },
            }
            : {
              line_item: {
                orderable_type: "Service",
                orderable_id: props.data.order_id,
                start_at: new Date(startDate).toString(),
                service_type: props.data?.service_type,
                ...(props.data?.service_type !== "one_time" && { end_at: new Date(endDate).toString() })
              },
            },
        props.countryId
      )
      .then((response) => {
        setAdding(false);
        if (response.success) {
          setServiceModalIsOpen(false);
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            setShowCart(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            setShowCart(true);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }
  const imageMagnifyEn = {
    zIndex: 9,
  };
  const imageMagnifyAr = {
    zIndex: 9,
    left: "-100%",
    marginLeft: "-10px",
  };
  return (
    <div className="item-cmp-desktop">
      {
        props.videoClicked ?
          <div
            style={{ direction: "ltr" }}
            className="img-div d-flex justify-content-center"
          >
            <div
              className="mb-1 innerhtml top-video-div"
              dangerouslySetInnerHTML={{
                __html: props.data["video"],
              }}
            ></div>
          </div>
          :
          <div
            style={{ direction: "ltr" }}
            className="img-div d-flex justify-content-center"
          >
            {props.activeImg && (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "",
                    isFluidWidth: true,
                    src: props.activeImg,
                  },
                  largeImage: {
                    src: props.activeImg,
                    width: 1000,
                    height: 850,
                  },
                  style: {
                    maxHeight: "100%",
                  },
                  imageStyle: {
                    maxHeight: "100%",
                    objectFit: "contain",
                  },
                  enlargedImageStyle: {
                    objectFit: "contain",
                    backgroundColor: "white"
                  },
                  enlargedImageContainerStyle:
                    props.language === "ar" ? imageMagnifyAr : imageMagnifyEn,
                }}
              />
            )}
          </div>
      }

      <div className="large-screen-none ">
        <Carousel controls={false}>
          {props.Img?.map((item, index) => (
            <Carousel.Item interval={2000} key={item.id} className="w-100">
              <div
                key={item.id}
                className="d-flex justify-content-center max-height-width"
              >
                <img
                  alt={index}
                  src={item.src}
                  style={{ width: "auto", maxWidth: "100%", height: "100%" }}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="text-div" style={{ textAlign: "initial" }}>
        {/* <div className="vendure-text">{props.data.order_vendor}</div> */}
        <div className="vendure-text123 d-flex align-items-center">
          <Link to={{
            pathname: "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/items/products",
            search: "?filter_by_brand%5B%5D=" + props.data.brand_id
          }}
          ><span className="inf">{t("item-detail.brand")}: </span>{props.data.brand_name}</Link>
          <span className="like-cont ml-auto">
            <span
              onClick={() => {
                if (localStorage.getItem("access_token")) {
                  moveToWishlist();
                } else {
                  setRedirect(true);
                }
              }}
              className="like-icon"
            >
              {loading ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <ClipLoader color="#fff" loading size={20} />
                </div>
              ) : props.data.wishlisted ? (
                <FaHeart size="20" color="#F64951" style={{ cursor: "pointer" }} />
              ) : (
                <FaRegHeart size="20" color="#ABB7C5" style={{ cursor: "pointer" }} />
              )}
            </span>
          </span>
        </div>

        <h1 className="item-title">
          {props.data?.order_title + " "}
          {props.data
            ? props.data[`variantName_${props.language}`]
              ? props.data[`variantName_${props.language}`]
              : ""
            : ""}
        </h1>
        {/* <div className="vendure-text">
          {props.data?.sku ? props.data[`sku`] : ""}
        </div> */}
        <div style={{ display: "flex" }}>
          {
            props.data?.sku ?
              <div className="vendure-text">
                <span>SKU:&nbsp;</span>{props.data[`sku`] ? props.data[`sku`] : ""}
              </div>
              :
              <></>
          }
          {
            props.data?.upc_code ?
              <>
                <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 18, border: "1px solid gray" }}></div>
                <div className="vendure-text">
                  <span>UPC Code:&nbsp;</span>{props.data?.upc_code ? props.data[`upc_code`] : ""}
                </div>
              </>
              :
              <></>
          }
        </div>
        <div>
          {
            props.data?.country_of_origin ?
              <div className="vendure-text">
                <span>Country:&nbsp;</span>{props.data?.country_of_origin ? props.data?.country_of_origin : ""}
              </div>
              :
              <></>
          }
        </div>
        <div className="prev-price small-screen-none">
          {/* {props.data.price_before_discount - props.data.price_after_discount >
            0 && t("item-detail.was")}
          {props.data.price_before_discount - props.data.price_after_discount >
            0 && (
              
              <span className="ml-4">
                <s>
                  {props.data.currency} {props.data.price_before_discount}
                </s>
              </span>
            )} */}
          <span
            className="rating-style"
            style={{
              marginInlineStart:
                props.data.price_before_discount -
                  props.data.price_after_discount <
                  1
                  ? "0px"
                  : "0px",
            }}
          >
            {parseFloat(props.data.rating).toFixed(2)}
          </span>
          <span className="rating-count">
            {props.data.rating_count} {t("item-detail.itemcmp-rating")}
          </span>
        </div>
        {
          props.item?.is_inquiry ?
            <></>
            :
            props.item?.product_type == "wholesale" ?
              <div>
                <div className="row price-row mx-0">
                  <div className="col-4 col1">
                    <p className="m-0 price">{props.item.currency} {props.item?.qty1range_price}</p>
                    <p className="m-0">{props.item?.qty1range_from} &lt; {props.item?.qty1range_to} {props.item?.unit}</p>
                  </div>
                  <div className="col-4 col2">
                    <p className="m-0 price">{props.item.currency} {props.item?.qty2range_price}</p>
                    <p className="m-0">{props.item?.qty2range_from} &lt; {props.item?.qty2range_to} {props.item?.unit}</p>
                  </div>
                  <div className="col-4 col-3">
                    <p className="m-0 price">{props.item.currency} {props.item?.qty3range_price}</p>
                    <p className="m-0">{props.item?.qty3range_from} &lt; {props.item?.qty3range_to} {props.item?.unit}</p>
                  </div>
                </div>
                {/* <table className="table table-bordered m-0">
                  <tr>
                    <th className="py-1">Quantity</th>
                    <th className="py-1">Price</th>
                  </tr>
                  <tr>
                    <td className="py-1">{props.data?.qty1range_from}-{props.data?.qty1range_to}</td>
                    <td className="py-1">{props.data.currency} {props.data?.qty1range_price}</td>
                  </tr>
                  {
                    props.data?.qty2range_from ?
                      <tr>
                        <td className="py-1">{props.data?.qty2range_from}-{props.data?.qty2range_to}</td>
                        <td className="py-1">{props.data.currency} {props.data?.qty2range_price}</td>
                      </tr>
                      :
                      <></>
                  }
                  {
                    props.data?.qty3range_from ?
                      <tr>
                        <td className="py-1">{props.data?.qty3range_from}-{props.data?.qty3range_to}</td>
                        <td className="py-1">{props.data.currency} {props.data?.qty3range_price}</td>
                      </tr>
                      :
                      <></>
                  }
                </table> */}
                <span className="inclusive-style p-0">
                  {t("item-detail.include-vat-text")}
                </span>
              </div>
              :
              <>
                <div className="prev-price small-screen-none priceblock">
                  {/* {props.data.price_before_discount - props.data.price_after_discount >
              0 && t("item-detail.now")} */}
                  <span
                    className={`${props.data.price_before_discount -
                      props.data.price_after_discount >
                      0
                      ? ""
                      : ""
                      } price-style`}
                  >
                    {props.data.currency}{" "}
                    {Math.round(
                      (parseFloat(props.data.price_after_discount) + Number.EPSILON) *
                      100
                    ) / 100}
                {props.data?.type == "Service" &&
                  (props.data?.service_type === "one_time"
                    ? <></>
                    : props.language == "en"
                    ? en_translation["item-detail"]["hourly-service"]
                    : ar_translation["item-detail"]["hourly-service"])}
                  </span>
                  {props.data.price_before_discount - props.data.price_after_discount >
                    0 && (

                      <span className="old-price">
                        <s>
                          {props.data.currency} {props.data.price_before_discount}
                          {props.data?.type == "Service" &&
                      (props.data?.service_type === "one_time"
                        ? props.language == "en"
                          ? en_translation["item-detail"]["one-time-service"]
                          : ar_translation["item-detail"]["one-time-service"]
                        : props.language == "en"
                        ? en_translation["item-detail"]["hourly-service"]
                        : ar_translation["item-detail"]["hourly-service"])}                     </s>
                      </span>
                    )}
                </div>
                <span className="price-style">{props.data?.type == "Service" &&
                  (props.data?.service_type === "one_time"
                    ? props.language == "en"
                      ? en_translation["item-detail"]["one-time-service"]
                      : ar_translation["item-detail"]["one-time-service"]
                    : <></>)}</span><br />
                <span className="inclusive-style">
                  {t("item-detail.include-vat-text")}
                </span>
              </>
        }
        <div className="large-screen-none">
          <div className="d-flex justify-content-between align-items-center">
            <span>
              <span className="price-style">
                {props.data.currency}{" "}
                {Math.round(
                  (parseFloat(props.data.price_after_discount) +
                    Number.EPSILON) *
                  100
                ) / 100}
              </span>
              <span
                style={{
                  fontSize: "13px",
                  color: "#7E859B",
                  marginLeft: "5px",
                }}
              >
                {props.data.price_before_discount -
                  props.data.price_after_discount >
                  0 && (
                    <s>
                      {props.data.currency}{" "}
                      {Math.round(
                        (parseFloat(props.data.price_before_discount) +
                          Number.EPSILON) *
                        100
                      ) / 100}
                    </s>
                  )}
              </span>
            </span>
            <span className="inclusive-style">
              {t("item-detail.inclusive-of-vat")}
            </span>
          </div>
        </div>
        {
          props.item?.is_inquiry || props.item?.product_type == "wholesale" ?
            <></>
            :
            <div>
              {parseFloat(
                props.data.price_before_discount - props.data.price_after_discount
              ) > 0 && (
                  <div className="prev-price small-screen-none">
                    {t("item-detail.saving")}
                    <span className="ml-4 saving-style">
                      {props.data.currency}{" "}
                      {Math.round(
                        (parseFloat(
                          props.data.price_before_discount -
                          props.data.price_after_discount
                        ) +
                          Number.EPSILON) *
                        100
                      ) / 100}
                    </span>
                    <span className="off-perc-style">
                      {(
                        ((props.data.price_before_discount -
                          props.data.price_after_discount) *
                          100) /
                        props.data.price_before_discount
                      ).toFixed(2)}
                      {t("item-detail.off")}
                    </span>
                  </div>
                )}
              {
                props.data.coupon ?
                  new Date(props.data.coupon.expiry) >= new Date() ?
                    <div className="coupon-box">
                      <p className="px-2 my-0">Coupon: {props.data.coupon.code}</p>
                    </div>
                    :
                    <></>
                  :
                  <></>
              }
            </div>
        }
        {/* {
          props.data.store_id == 88 ?
            <div>
              <Link
                to={{
                  pathname: "/" + props.countryUrl + "-" + props.language + "/winter-sale"
                }}
              >
                <img src={Banner} className="img img-fluid" />
              </Link>
            </div>
            :
            <></>
        } */}
        <span
          className={`large-screen-none off-perc-style ${parseFloat(
            props.data.price_before_discount - props.data.price_after_discount
          ) > 0
            ? ""
            : " invisible"
            }`}
        >
          {(
            ((props.data.price_before_discount -
              props.data.price_after_discount) *
              100) /
            props.data.price_before_discount
          ).toFixed(2)}
          {t("item-detail.off")}
        </span>
        {props.data.type === "Product" &&
          variantList.map((variant, index) => (
            <div key={index} className="mb-3 mt-3 variatspace">
              {active === variant.id && (
                <span className="variation-text">
                  {t("item-detail.variation")}
                  {": "}
                  <strong>{variant.variation}</strong>
                </span>
              )}
            </div>
          ))}
        {props.data.type === "Product" ? (
          <div className="city-cont">
            {variantList.map((variant) => (
              <Link
                to={{
                  pathname: "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    `/${props.item.category_title_en?.toLowerCase().replace(/ /g, "-")}/${props.item.slug
                    }`,
                  search: "?sku=" + encodeURIComponent(variant.sku)
                }}
                onClick={() => {
                  if (active !== variant.id) {
                    handleChange({ value: variant.id });
                    setActive(variant.id);
                  }
                }}
                className={
                  active === variant.id
                    ? "selected-span px-3 py-2 mb-2"
                    : "simple-span px-3 py-2 mb-2"
                }
                key={variant.id}
              >
                <img
                  src={variant.featured_image}
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "100%",
                  }}
                  alt=""
                />
              </Link>
            ))}
          </div>
        ) : (
          <></>
        )}
        {
          props.data.type == "Service" && (
            <p>{
              props.language == "en" ?
                en_translation["item-detail"]["service-text"]
                :
                ar_translation["item-detail"]["service-text"]
            }</p>
          )
        }
        <div className="quantity-cont small-screen-none">
          {/* {(props.data.order_quantity && !props.item?.is_inquiry) || props.data.product_type == "wholesale" && (
            <div className="quantity-heading small-screen-none">
              {t("item-detail.quantity")}
            </div>
          )} */}
          <div className="quantity-body ">
            <div className="d-flex align-items-center">
              {
                props.data?.product_type == "wholesale" ?
                  props.item?.is_inquiry ?
                    <></>
                    :
                    <>
                      <label className="m-0">Qty:&nbsp;</label>
                      <span className="dropdown-cont">
                        <input
                          type="text"
                          className="form-control"
                          value={quantity}
                          style={{ width: "60px" }}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </span>
                    </>
                  :
                  props.data.order_quantity && !props.item?.is_inquiry && (
                    <>
                      <label className="m-0">Qty:&nbsp;</label>
                      <span className="dropdown-cont">
                        <Dropdown
                          options={quantityVal}
                          onChange={(e) => setQuantity(e)}
                          value={quantity}
                          placeholderClassName="dropdownPlaceholder"
                          menuClassName="dropdownMenu"
                          className="dropdown-outer"
                        />
                      </span>
                    </>
                  )}
            </div>
            {props.item?.assembly && (
              <AssemblyServiceModel
                assemblyPrice={`${props.data.currency} ${props.item.assembly?.price}`}
                addAssembly={addAssembly}
                setAddAssembly={setAddAssembly}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            )}
            {props.data.type === "Product" ? (
              props.data.is_inquiry ?
                <a
                  href={encodeURI(`//api.whatsapp.com/send?phone=971543064845&text=Hi, I would like to inquire about the product. I am interested in : ${props.item?.order_title}. Check here: ${(window.location.href).replace("&", "%26")}`)}
                  target="_blank"
                  // onClick={() => props.data.order_quantity && addToCart()}
                  className={`${!props.data.order_quantity ? "get-inquiry-btn w-100" : "get-inquiry-btn w-100"
                    }`}
                >
                  <span className="add-to-cart-text w-100">
                    {adding ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <ClipLoader color="#fff" loading size={20} />
                      </div>
                    ) : props.data.order_quantity ? (
                      <div className="d-flex align-items-center w-100">
                        <FaWhatsapp
                          size={24}
                          color="white"
                        />
                        <p className="my-0 mx-auto">{t("item-detail.get-inquiry")}</p>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center w-100">
                        <FaWhatsapp
                          size={24}
                          color="white"
                        />
                        <p className="my-0 mx-auto">{t("item-detail.get-inquiry")}</p>
                      </div>
                    )}
                  </span>
                </a>
                :
                <span
                  onClick={() => {
                    if (props.data.order_quantity || props.data.product_type == "wholesale") {
                      addToCart()
                    }
                  }}
                  className={`${props.data.order_quantity || props.data.product_type == "wholesale" ? "add-to-cont" : "out-of-stock"
                    }`}
                >
                  <span className="add-to-cart-text">
                    {adding ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <ClipLoader color="#fff" loading size={20} />
                      </div>
                    ) : props.data.order_quantity || props.data.product_type == "wholesale" ? (
                      props.language == "en" ?
                        en_translation["item-detail"]["add-to-cart"]
                        :
                        ar_translation["item-detail"]["add-to-cart"]
                    ) : (
                      t("item-detail.sold-out")
                    )}
                  </span>
                </span>
            ) : (
              <span onClick={() => setServiceModalIsOpen(true)} className="add-to-cont">
                <span className="add-to-cart-text">
                  {adding ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={20} />
                    </div>
                  ) : (
                    props.language == "en" ?
                      en_translation["item-detail"]["order-service"]
                      :
                      ar_translation["item-detail"]["order-service"]
                  )}
                </span>
              </span>
            )}
          </div>
          {
            props.data.type == "Product" ?
              props.data.is_inquiry ?
                <></>
                :
                props.data.order_quantity || props.data.product_type == "wholesale" ?
                  <span
                    onClick={() => {
                      if (props.data.order_quantity || props.data.product_type == "wholesale") {
                        buyNow()
                      }
                    }}
                    className={`${props.data.order_quantity || props.data.product_type == "wholesale" ? "buy-now-btn" : "out-of-stock"
                      }`}
                  >
                    <span className="buy-now-text">
                      {buyNowClicked ? (
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                          <ClipLoader color="#fff" loading size={20} />
                        </div>
                      ) : props.data.order_quantity || props.data.product_type == "wholesale" ? (
                        t("item-detail.buy-now")
                      ) : (
                        t("item-detail.out-of-stock")
                      )}
                    </span>
                  </span>
                  :
                  <div className="notify-me-btn">
                      <span className="notify-me-btn-text" onClick={() => setShowNotifyModel(true)}>
                        {adding ? (
                          <div className="d-flex justify-content-center align-items-center w-100 h-100">
                            <ClipLoader color="#fff" loading size={20} />
                          </div>
                        ) : (

                          t("item-detail.notify-me")
                        )}
                      </span>
                  </div>
              :
              <></>
          }
        </div>
      </div>
      <NotifyUser
        setShowNotifyModel={setShowNotifyModel}
        showNotifyModel={showNotifyModel}
        id={props.data.variantId}
        language={props.language}
      />
      {showCart && <CartSidebar refs={sideCartRef} bool={showCart} />}
      {redirect && <Redirect push to="/login" />}
      {checkout_redirect.value && (
        <Redirect
          push
          to={{
            pathname: checkout_redirect.path,
            state: checkout_redirect.state,
          }}
        />
      )}
      <Modal
        show={serviceModalIsOpen}
        onHide={() => setServiceModalIsOpen(false)}
        closeModal={() => setServiceModalIsOpen(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="service-modal"
        centered
      >
        <Modal.Body>
          <div className="p-4">
            <div className="prev-price mb-1">
              {props.data?.service_type === "one_time"
                ? t("item-detail.select-date-and-time")
                : t("item-detail.start-date")}:
            </div>
            <DateAndTimePickers
              ref={startDateRef}
              label="Start date"
              onChange={(val) => {
                if (
                  (props.width !== "sm"
                    ? moment(new Date(endDate)).format("YYYY-MM-DDTHH:mm")
                    : moment(new Date(props.endDate)).format(
                        "YYYY-MM-DDTHH:mm"
                      )) > moment(new Date(val)).format("YYYY-MM-DDTHH:mm") ||
                  props.data?.service_type === "one_time"
                ) {
                  if (
                    moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                      moment(new Date()).format("YYYY-MM-DDTHH:mm") ||
                    moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                      moment(
                        new Date(
                          props.width !== "sm" ? startDate : props.startDate
                        )
                      ).format("YYYY-MM-DDTHH:mm")
                  ) {
                    props.width !== "sm"
                      ? setStartDate(val)
                      : props.onChangeStart(val);
                  } else {
                    startDateRef.current.setDateValue(props.width !== "sm" ? startDate : props.startDate)

                    message =
                      "Start date time can not be less than current date time";
                    showError();
                  }
                } else {
                  startDateRef.current.setDateValue(
                    props.width !== "sm" ? startDate : props.startDate
                  );
                  message = "Start date should be less than end date";
                  props.width !== "sm"
                    ? showError(message)
                    : showErrorTop(message);
                }
              }}
              width="100%"
            />
            {props.data?.service_type !== "one_time" && (
              <div className="prev-price mb-1">
                <div className="prev-price mb-1">
                  {t("item-detail.end-date") + ":"}
                </div>

                <DateAndTimePickers
                  ref={endDateRef}
                  label="End date"
                  onChange={(val) => {
                    if (
                      moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                      (props.width !== "sm"
                        ? moment(new Date(startDate)).format("YYYY-MM-DDTHH:mm")
                        : moment(new Date(props.startDate)).format(
                            "YYYY-MM-DDTHH:mm"
                          ))
                    ) {
                      props.width !== "sm"
                        ? setEndDate(val)
                        : props.onChangeEnd(val);
                    } else {
                      endDateRef.current.setDateValue(
                        props.width !== "sm" ? endDate : props.endDate
                      );
                      message = "End date should be greater than start date";
                      props.width !== "sm"
                        ? showError(message)
                        : showErrorTop(message);
                    }
                  }}
                  width="100%"
                />
              </div>
            )}
            <div
              style={{
                margin: "127px 0px 30px",
              }}
            >
              <span style={{ fontWeight: 600 }}>{t("item-detail.note")}</span>
              {t("item-detail.service-note")}
            </div>

            <span onClick={() => addToCart()} className="continue-btn">
                <span className="add-to-cart-text">
                  {adding ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={20} />
                    </div>
                  ) : (
                    props.language == "en" ?
                      en_translation["item-detail"]["continue"]
                      :
                      ar_translation["item-detail"]["continue"]
                  )}
                </span>
              </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    item: state.item.item,
    width: state.layout.screenWidth,
    language: state.language.language,
    countryId: state.language.countryId,
    countryUrl: state.language.countryUrl,
    cart_items_length: state.cart.cart_items_length,
    auth: state.auth.isSignedIn,
  };
};

const mapDispatchToProps = {
  set_single_item,
  set_cart_items
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCmp);
