import React, { Fragment, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaCheckCircle, FaPlusSquare } from "react-icons/fa";

import "../../../assets/styles/customer/cart/cartsidebarproductcard.scss";
import QuantityCounter from "../checkout/QuantityCounter";
import { apiJson } from "../../../Api";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { getCurrencyOfCountry, getDesktopImage } from "../../../utils/utils";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import AssemblyServiceModel from "../AssemblyServiceModel";
import wrench from "../../../assets/images/wrench.png";

const SidebarProductCard = (props) => {
  const { t } = useTranslation();
  const { product } = props;

  const [updatingQuantity, setUpdatingQuantity] = useState(false);
  const [updatingStartDate, setUpdatingStartDate] = useState(false);
  const [updatingEndDate, setUpdatingEndDate] = useState(false);
  const [removeItemLoader, setRemoveItemLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [addAssembly, setAddAssembly] = useState(false)
  
  let message = "";
  const qc = useRef();

  const showError = () => {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function renderDay(props, currentDate, selectedDate) {
    return (
      <td stlyle={{ fontSize: "13px" }} {...props}>
        {currentDate.date()}
      </td>
    );
  }
  function renderMonth(props, month, year, selectedDate) {
    return <td {...props}>{month}</td>;
  }
  function renderYear(props, year, selectedDate) {
    return <td {...props}>{year % 100}</td>;
  }
  const removeItem = (id) => {
    setUpdatingQuantity(true);
    apiJson.cart.removeItem(id, props.countryId).then((response) => {
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });

        setUpdatingQuantity(false);
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
        setUpdatingQuantity(false);
      } else {
        setUpdatingQuantity(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };

  const removeItemFromCart = (id) => {
    setRemoveItemLoader(true);
    apiJson.cart.removeItem(id, props.countryId).then((response) => {
      if (response.success) {
        props.set_cart_items({
          cart_items: response.data,
        });
        setRemoveItemLoader(false);
      } else if (!response.status) {
        message = t("errors.wrong");
        showError();
        setRemoveItemLoader(false);
      } else {
        setRemoveItemLoader(false);
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
            ? response.errors
            : t("errors.wrong");
          showError();
        } else {
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        }
      }
    });
  };
  const updateQuantity = (opt, value, id, assemblyAdded, assembly_id) => {
    setUpdatingQuantity(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            quantity: value,
            is_assembly_added: assemblyAdded || false,
            assembly_id: assemblyAdded ? assembly_id : null,
          },
        },
        props.countryId
      )
      .then((response) => {
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          qc.current.setCounValue(product.quantity);

          message = t("errors.wrong");
          showError();
        } else {
          qc.current.setCounValue(product.quantity);

          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }

        setUpdatingQuantity(false);
      });
  };
  const updateStartTime = (id, val) => {
    setUpdatingStartDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(val).toString(),
            service_type: product.orderable.service_type,
            ...(product.orderable.service_type !== "one_time" && { end_at: new Date(product.end_at).toString() }),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingStartDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };
  const updateEndTime = (id, val) => {
    setUpdatingEndDate(true);
    apiJson.cart
      .updateProduct(
        id,
        {
          line_item: {
            start_at: new Date(product.start_at).toString(),
            end_at: new Date(val).toString(),
          },
        },
        props.countryId
      )
      .then((response) => {
        setUpdatingEndDate(false);
        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
          });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <Fragment>
      <Row className="cart-sidebar-product-card-container-desktop no-gutters">
        <Col className="main">
          <div className="image-container">
            <img
              className="image"
              src={getDesktopImage(
                product.orderable.featured_image_resized,
                product.orderable.featured_image
              )}
              alt="product"
            />
          </div>
        </Col>
        <Col className="ml-2 details-container">
          <div className="description">
            <p>
              {product?.orderable[`product_name_${props.language}`]
                ? product.orderable[`product_name_${props.language}`] + " "
                : ""}
              {product?.orderable[`name_${props.language}`]
                ? product.orderable[`name_${props.language}`] + " "
                : ""}
            </p> 
          </div>
          <div className="sku">
              {product?.orderable[`sku`] ? product.orderable[`sku`] : ""} 
              <div
                className="remove-btn"
                onClick={() => removeItemFromCart(product.id)}
                style={{ cursor: "pointer", fontSize: "12px" }}
              >
                {removeItemLoader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <ClipLoader color="#000" size={17} />
                  </div>
                ) : (
                  <>
                    <MdDelete style={{ color: "#7e859b"}} className="mb-1" />
                    {t("checkout.btn-remove")}
                  </>
                )}
              </div>
          </div>
          
          <div className="qunatity-and-price">
            {product.quantity ? (
              <div className=" quantity-container">
                <QuantityCounter
                  ref={qc}
                  addValue={(value) => updateQuantity("add", value, product.id)}
                  subValue={(value) => updateQuantity("sub", value, product.id)}
                  value={product.quantity}
                  loading={updatingQuantity}
                  isQunatityUpdatedable={!!product?.assembly?.id}
                  remove={() => removeItem(product.id)}
                />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="date-heading mb-1">
                  { product.orderable.service_type === "one_time" ? t("item-detail.date-and-time") : t("item-detail.start-date") + ":"}
                </div>

                <div style={{ width: "200px" }} className="mb-2">
                  <Datetime
                    onChange={(val) => {
                      if (
                        moment(new Date(val)).format("YYYY-MM-DDTHH:mm") <
                          moment(new Date(product.end_at)).format(
                            "YYYY-MM-DDTHH:mm"
                          ) ||
                        product.orderable.service_type === "one_time"
                      ) {
                        if (
                          moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                            moment(new Date()).format("YYYY-MM-DDTHH:mm") ||
                          moment(new Date(val)).format("YYYY-MM-DDTHH:mm") >
                            moment(new Date(product.start_at)).format(
                              "YYYY-MM-DDTHH:mm"
                            )
                        ) {
                          updateStartTime(product.id, val);
                        } else {
                          message =
                            "Start date time can not be less than current date time";
                          showError();
                        }
                      } else {
                        message = "Start date should be less than end date";
                        showError();
                      }
                    }}
                    value={new Date(product.start_at)}
                    renderDay={renderDay}
                    renderMonth={renderMonth}
                    renderYear={renderYear}
                    inputProps={{
                      disabled: updatingStartDate,
                    }}
                  />
                </div>
                {product.orderable.service_type !== "one_time" && (
                  <>
                    <div className="date-heading mb-1">
                      {t("item-detail.end-date") + ":"}
                    </div>

                    <div style={{ width: "200px" }} className="mb-2">
                      <Datetime
                        onChange={(val) => {
                          if (
                            moment(new Date(product.start_at)).format(
                              "YYYY-MM-DDTHH:mm"
                            ) < moment(new Date(val)).format("YYYY-MM-DDTHH:mm")
                          ) {
                            updateEndTime(product.id, val);
                          } else {
                            message =
                              "End date should be greater than start date";
                            showError();
                          }
                        }}
                        value={new Date(product.end_at)}
                        renderDay={renderDay}
                        renderMonth={renderMonth}
                        renderYear={renderYear}
                        inputProps={{
                          disabled: updatingEndDate,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="price-container">
              {product.quantity && (
                <p className="para">
                  <span className="sp-count">{product.quantity} </span>
                  <span className="sp-sign">x</span>
                  <span className="sp-total">
                    {getCurrencyOfCountry(props.country)}{" "}
                    {(product.price / product.quantity).toFixed(2)}
                  </span>
                </p>
              )}
            </div>
          </div>
          {!product.quantity && (
            <div className="">
              <div className="price">
                {getCurrencyOfCountry(props.country)} {product.price}
              </div>
            </div>
          )}
        </Col>

        {product.orderable.assembly &&
          (product.assembly ? (
            <div className="confirmation-container w-80" style={{ borderTop: "1px solid #e5e9f2", borderBottom: "1px solid #e5e9f2", padding: "5px 0px"}}>
              <div className="remove-btn" style={{ cursor: "pointer" }}>
                <div className={"checked"}>
                  <img src={wrench} height={16} width={16} />{" "}
                  {t("cart-sidebar.assembly-price-included")}
                </div>
                <div
                  onClick={() =>
                    updateQuantity(
                      "",
                      product.quantity,
                      product.id,
                      false,
                      null
                    )
                  }
                >
                  <MdDelete className="mb-1" />
                  {t("cart-sidebar.remove-assembly")}
                </div>
              </div>
              <div
                className={"checked"}
                style={{ color: "#7d252a", fontWeight: 600, fontSize: "0.7rem" }}
              >
                {getCurrencyOfCountry(props.country)} {product.assembly.price}
              </div>
            </div>
          ) : (
            <span
              style={{
                fontSize: "12px",
                marginLeft: "auto",
                marginRight: "15px",
                cursor: "pointer"
              }}
              onClick={() => setIsOpen(true)}
            >
            <img src={wrench} height={16} width={16} />{" "}
            {t("cart-sidebar.add-assembly")}
            </span>
          ))}

        <div className="confirmation-container w-80">
          <div className={"checked"} style={{ marginLeft: "auto"}}>
            {t("cart-sidebar.added-to-cart")} <FaCheckCircle color="#3966e0" />
          </div>
        </div>
      </Row>

      {product.orderable.assembly && (
        <AssemblyServiceModel
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          addAssembly={addAssembly}
          setAddAssembly={setAddAssembly}
          assemblyPrice={
            product.orderable.assembly.price + " " + getCurrencyOfCountry(props.country)
          }
          showButton={true}
          onUpdate={(isAdd) =>
            updateQuantity(
              "",
              product.quantity,
              product.id,
              isAdd,
              product.orderable.assembly.id
            )
          }
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    country: state.language.country,
    countryId: state.language.countryId,
  };
};

const mapDispatchToProps = { set_cart_items };

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProductCard);
